import getConfig from './config';
import { version } from '../../../package.json';
import sendBeacon, { isBeaconApiSupported } from '../helper/sendBeacon';
import postTracking from '../helper/postTracking';
import getWhitelistedSearchParams from '../helper/getWhitelistedSearchParams';
import { Promise, getTrackingEndpointUrl } from '../../../lib/';
import { NWT_TRACKING_PATH } from '../../../constants';

const tracker = {};

const sendEvent = (eventData, endpointUrl, screenUrl) => {
  const payload = Array.isArray(eventData) ? eventData : [eventData];
  payload.forEach(event => {
    event.channel = event.channel || 'web';
    event.event_timestamp = Date.now();
    event.screen_url = `${screenUrl.hostname}${screenUrl.pathname}`;
    event.screen_resolution =
      window.screen && `${window.screen.width}x${window.screen.height}`;
    event.sdk_name = `ttt${event.sdk_name ? ',' + event.sdk_name : ''}`;
    event.sdk_version = `${version}${
      event.sdk_version ? ',' + event.sdk_version : ''
    }`;

    if (event.event === 'viewed_screen') {
      const whiteListedParamsString = getWhitelistedSearchParams(screenUrl);
      if (whiteListedParamsString && event.query) {
        event.query += encodeURIComponent(`&${whiteListedParamsString}`);
      } else if (whiteListedParamsString && !event.query) {
        event.query = encodeURIComponent(whiteListedParamsString);
      }
    }
  });

  return new Promise(resolve => {
    if (isBeaconApiSupported()) {
      resolve(sendBeacon(endpointUrl, payload));
    } else {
      resolve(postTracking(endpointUrl, payload));
    }
  });
};

tracker.track = (event, env, url) => {
  sendEvent(
    event,
    getTrackingEndpointUrl(getConfig(env), env, NWT_TRACKING_PATH),
    url
  );
};

export default tracker;
