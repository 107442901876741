export const MAP_RESPONSE_FIELDS_TO_NAMES = {
  'signup_minireg[first_name]': 'firstName',
  'signup_minireg[last_name]': 'lastName',
  'signup_minireg[password]': 'password',
  'signup_minireg[email]': 'email',
  'signup_minireg[email_domain]': 'emailDomain',
  'signup_minireg[signup_channel]': 'signupChannel',
  'signup_minireg[skip_email_domain_check]': 'skipEmailDomainCheck',
  'signup_minireg[check_secondary_fields]': 'checkSecondaryFields',
  'signup_minireg[base]': 'base',
};

export const JSON_HEADERS = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json',
};

export const INITIAL_FORM_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  tandcCheck: '1',
  gRecaptchaResponse: null,
  skipEmailDomainCheck: false,
  checkSecondaryFields: true,
};
