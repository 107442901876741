import 'cross-fetch/polyfill';

import { Mixin, strategies } from '@xingternal/hops-mixin';
import fetchJson from '../../fetchJson';

const createRequest = (input, init) => {
  if (typeof input === 'string') {
    const { credentials = 'same-origin', ...options } = init || {};

    return new Request(input, { ...options, credentials });
  } else {
    return new Request(input, init);
  }
};

class HopsFetchBaseRuntimeMixin extends Mixin {
  fetch(...fetchArgs) {
    if (!this.configuredFetch) {
      this.configuredFetch = this.configureFetch(global.fetch);
    }

    return this.configuredFetch(createRequest(...fetchArgs));
  }

  fetchJson(input, options = {}) {
    return fetchJson(createRequest(input, options), { fetch: this.fetch });
  }
}

HopsFetchBaseRuntimeMixin.strategies = {
  configureFetch: strategies.sync.pipe,
  fetch: strategies.async.override,
  fetchJson: strategies.async.callable,
};

export default HopsFetchBaseRuntimeMixin;
