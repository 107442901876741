import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import {
  PasswordStrengthIndicatorText,
  PasswordStrengthIndicator,
} from './PasswordStrengthMeter.styled';

import strength from './strength';
import messages from './messages';

const calculateStrength = password => {
  if (typeof password !== 'string') return 0;
  if (password.length < 8) return 0;

  return strength(password);
};

const IndicatorText = ({ password, strength, error }) => {
  if (error) return error;
  if (password === '') return '\u00A0';
  if (password.length < 8)
    return <FormattedMessage {...messages.passwordTooShort} />;

  return <FormattedMessage {...messages[`strength${strength}`]} />;
};

const PasswordStrengthMeter = ({ password, error = null }) => {
  const strength = error ? 1 : calculateStrength(password, error);

  return (
    <div>
      <PasswordStrengthIndicator strength={strength} />
      <PasswordStrengthIndicatorText error={error}>
        <IndicatorText error={error} password={password} strength={strength} />
      </PasswordStrengthIndicatorText>
    </div>
  );
};

PasswordStrengthMeter.propTypes = {
  password: PropTypes.string,
  error: PropTypes.string,
};

export default PasswordStrengthMeter;
