import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconHide, IconShow } from '@xingternal/icons';
import { FormField } from '@xingternal/text-field';
import { Button } from '@xingternal/button';

const PasswordInput = ({
  name = 'password',
  onBlur,
  onChange,
  label,
  required,
  placeholder,
  value,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleIsVisible = () => setIsVisible(!isVisible);

  const Icon = isVisible ? IconHide : IconShow;

  return (
    <FormField
      size="medium"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      action={<Button size="small" icon={Icon} onMouseDown={toggleIsVisible} />}
      required={required}
      value={value}
      type={isVisible ? 'text' : 'password'}
      {...props}
    />
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default PasswordInput;
