import {
  assign,
  Promise,
  getCookie,
  setCookie,
  getSecondLevelDomain,
  uuid,
  determineActualUrl,
} from '../lib/';
import getPerformanceMetrics from './getPerformanceMetrics';
import createSandbox from './createSandbox';
import clearParams from './clearParams';
import {
  API_VERSION,
  TTT_VERSION,
  PARAM_REGEX,
  DEDUPLICATION_ID_ALLOWLIST,
} from '../constants';
import abacus from './trackers/abacus';
import nwt from './trackers/nwt';

const isRegex = value => value instanceof RegExp;
const isString = value => typeof value === 'string';

const createClearParamsFilter = clearParams => {
  if (typeof clearParams === 'function') {
    return clearParams;
  } else if (clearParams === true) {
    return param => PARAM_REGEX.test(param);
  } else if (isRegex(clearParams)) {
    return param => clearParams.test(param);
  } else if (isString(clearParams)) {
    return param => clearParams === param;
  } else {
    return null;
  }
};

const splitConfigs = (env, config) => {
  // filter ttt only configuration
  const { clearParams, host, sandboxFile, sandboxUrl, ...stmConfig } = config;
  const tttConfig = {
    clearParams,
    host,
    sandboxFile,
    sandboxUrl,
  };

  return [buildTTTConfig(env, tttConfig), buildSTMConfig(env, stmConfig)];
};

const buildSTMConfig = (env, config) => {
  const { search } = window.location || {};
  return assign(
    {
      debug: /debug=true/.test(search),
    },
    config,
    {
      env,
    }
  );
};

const buildTTTConfig = (env, config) => {
  const {
    clearParams,
    host: sandboxHost = env === 'production'
      ? 'stan.xing.com'
      : 'preview-stan.xing.com',
    sandboxFile = `stm-${API_VERSION}.html`,
    sandboxUrl = `https://${sandboxHost}/${sandboxFile}`,
  } = config;

  return { clearParams, sandboxUrl };
};

const throwInitError = () => {
  throw new Error('Please call ttt.init first');
};

const setupTracking = window => {
  const ttt = {
    version: TTT_VERSION,
    baseData: {},
    _dl: {},
    _sandbox: createSandbox(window),
    _clearParamsFilter: undefined,
  };

  const assertInitialization = () => {
    if (!ttt._initCalled) {
      throwInitError();
    }
  };

  ttt.assign = assign;

  ttt.init = (env, config = {}) => {
    // return silently, because it should be possible to call init multiple times
    if (ttt._initCalled) return Promise.resolve();

    ttt._initCalled = true;

    ttt._env = env;

    const [tttConfig, stmConfig] = splitConfigs(env, config);
    const { clearParams = true, sandboxUrl } = tttConfig;
    ttt._sandbox.load(sandboxUrl);

    if (clearParams) {
      ttt._clearParamsFilter = createClearParamsFilter(clearParams);
    }

    return ttt._sandbox.publish('stm:init', stmConfig);
  };

  ttt.pageview = (channel, pageName, baseData, contextData) => {
    assertInitialization();

    // copy base data for later usage
    ttt.baseData = assign({}, baseData);

    // create the data layer for events
    ttt._dl = assign({}, baseData, {
      PropChannel: channel,
      pageName,
      PropDestinationUrl: window.location.href,
      PropReferringUrl:
        (ttt._dl && ttt._dl.PropDestinationUrl) || window.document.referrer,
    });

    if (ttt._clearParamsFilter) {
      ttt._clearParams(ttt._clearParamsFilter);
    }

    return ttt._rawEvent('pageview', assign({}, ttt._dl, contextData));
  };

  ttt.event = (name, contextData) => {
    assertInitialization();

    const props = assign({}, ttt._dl, contextData);

    return ttt._rawEvent(name, props);
  };

  ttt.nwtEvent = eventData => {
    assertInitialization();
    const screenUrl = determineActualUrl(
      ttt._dl.PropDestinationUrl,
      window.location.href
    );
    nwt.track(eventData, ttt._env, screenUrl);
  };

  ttt.withPerformanceMetrics = action => {
    assertInitialization();

    return new Promise(resolve => {
      const resolveWithMetrics = () => {
        setTimeout(() => {
          const times = getPerformanceMetrics(window);
          const metrics = Object.keys(times)
            .map(name => `${name}=${times[name]}`)
            .join(';');

          const props = { PropTechnicalPerformance: metrics };

          action && action(props);

          resolve(props);
        }, 0);
      };

      if (
        (window.document && window.document.readyState === 'complete') ||
        !window.addEventListener
      ) {
        resolveWithMetrics();
      } else {
        const onReady = () => {
          resolveWithMetrics();

          window.removeEventListener('load', onReady, true);
        };

        window.addEventListener('load', onReady, true);
      }
    });
  };

  ttt.publish = (action, payload, options = {}) => {
    assertInitialization();

    return ttt._sandbox.publish(action, payload, options);
  };

  ttt.subscribe = (name, callback) => {
    assertInitialization();

    return ttt._sandbox.subscribe(name, callback);
  };

  ttt._clearParams = filter => {
    assertInitialization();

    return clearParams(filter);
  };

  ttt._rawEvent = (event, props) => {
    assertInitialization();

    const hostname = getSecondLevelDomain();
    const options = {
      path: '/',
    };

    if (hostname !== null) {
      options.domain = hostname;
    }

    const prevPage = getCookie('prevPage') || 'FirstPageOfVisit';
    if (props.pageName) {
      setCookie('prevPage', props.pageName, 1800000, options);
    }

    props = assign({}, props, {
      PropLastPage: prevPage,
      PropLastPageCookieSet: 1, // can be removed once ttt 1.8.1 is rolled out completely
    });

    if (DEDUPLICATION_ID_ALLOWLIST.includes(props.pageName?.split('/')[0])) {
      props = assign(props, {
        PropDeduplicationId: uuid(),
      });
    }
    return Promise.all([
      abacus.track(ttt._env, event, props),
      ttt._sandbox.publish('stm:track', { event, props }),
    ]);
  };

  ttt._rawPageview = ttt._rawEvent.bind(null, 'pageview');

  ttt._unload = () => {
    return ttt._sandbox.unload();
  };

  return ttt;
};

export default setupTracking;
export {
  splitConfigs,
  buildTTTConfig,
  buildSTMConfig,
  createClearParamsFilter,
};
