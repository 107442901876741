import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';

import { XingFrameConsumer } from '@xingternal/xing-frame-provider';

import {
  ViewWrapper,
  ViewWrapperDefaultGrid,
} from '@xingternal/platform-layout-view-wrapper';

import { Header } from './Header';

const sharedPropTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  interceptClose: PropTypes.func,
  plainOmView: PropTypes.bool,
  trigger: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  preventPropagation: PropTypes.bool,
};

class OmViewGridLess extends React.Component {
  triggerElement;
  deactivateOmView;
  activateOmView;
  omViewAnimationDuration;
  static scrollPosition = { x: 0, y: 0 };
  onCloseTimeOut;

  static propTypes = sharedPropTypes;

  state = {
    isOpen: false,
  };

  componentDidMount() {
    window.addEventListener('popstate', this.closeAndPopState);
  }

  componentWillUnmount() {
    clearTimeout(this.onCloseTimeOut);
    this.triggerElement = undefined;
    window.removeEventListener('popstate', this.closeAndPopState);
  }

  closeAndPopState = () => {
    this.handleDeactivate(false);
  };

  setScrollPosition() {
    this.scrollPosition = { x: global.pageXOffset, y: global.pageYOffset };
  }

  handleOnCloseOnKeyUp = (e) => {
    const { key, keyCode } = e;
    if (key === 'Escape' || keyCode === 27) {
      this.handleOnClose();
    }
  };

  handleActivate = (e) => {
    this.setScrollPosition();
    global.history.pushState(null, document.title, global.location.href);
    this.triggerElement = e && e.currentTarget ? e.currentTarget : undefined;
    this.setState({
      isOpen: true,
    });
    global.addEventListener('keyup', this.handleOnCloseOnKeyUp);
    this.activateOmView();
    setTimeout(() => {
      global.scrollTo(0, 0);
    }, this.omViewAnimationDuration);
  };

  handleDeactivate = (popState = true) => {
    if (!this.state.isOpen) {
      return;
    }

    if (popState) {
      global.history.back();
    }
    global.removeEventListener('keyup', this.handleOnCloseOnKeyUp);
    this.deactivateOmView();
    setTimeout(() => {
      const { x, y } = this.scrollPosition;
      global.scrollTo(x, y);
    }, 0);
    this.onCloseTimeOut = setTimeout(() => {
      this.setState({ isOpen: false });
      if (this.triggerElement && this.triggerElement.focus) {
        this.triggerElement.focus();
      }
    }, this.omViewAnimationDuration);
  };

  handleOnClose = () => {
    if (typeof this.props.interceptClose === 'function') {
      this.props.interceptClose();
    } else {
      this.handleDeactivate();
      if (typeof this.props.onClose === 'function') {
        this.props.onClose();
      }
    }
  };

  render() {
    const { children, trigger, className, preventPropagation, plainOmView } =
      this.props;
    const { isOpen } = this.state;

    return (
      <XingFrameConsumer>
        {({
          activateOmView,
          deactivateOmView,
          omViewAnimationDuration,
          omViewRef,
        }) => {
          this.activateOmView = activateOmView;
          this.deactivateOmView = deactivateOmView;
          this.omViewAnimationDuration = omViewAnimationDuration;
          return (
            <React.Fragment>
              {trigger(this.handleActivate)}
              {isOpen &&
                omViewRef.current &&
                ReactDom.createPortal(
                  <ViewWrapper
                    className={className}
                    preventPropagation={preventPropagation}
                    Header={
                      !plainOmView && <Header onClose={this.handleOnClose} />
                    }
                  >
                    {typeof children === 'function'
                      ? children({ handleClose: this.handleDeactivate })
                      : children}
                  </ViewWrapper>,
                  omViewRef.current
                )}
            </React.Fragment>
          );
        }}
      </XingFrameConsumer>
    );
  }
}

const OmView = ({ children, ...props }) => {
  return (
    <OmViewGridLess {...props}>
      {({ handleClose }) => (
        <ViewWrapperDefaultGrid>
          {typeof children === 'function'
            ? children({ handleClose })
            : children}
        </ViewWrapperDefaultGrid>
      )}
    </OmViewGridLess>
  );
};

OmView.propTypes = sharedPropTypes;

export { OmView, OmViewGridLess };
