// NOTE this key is shared with legal pages api:
// https://source.xing.com/utec/legal-pages/blob/master/app/controllers/api/user_consent_controller.rb#L13
const userConsentCookie = 'userConsent';

const cookieDomain = () => {
  const hostParts = window.location.hostname.split('.');
  const secondLevelDomain = hostParts[hostParts.length - 2];
  const firstLevelDomain = hostParts[hostParts.length - 1];

  return secondLevelDomain
    ? `.${secondLevelDomain}.${firstLevelDomain};`
    : firstLevelDomain;
};

const deleteCookie = (name) => {
  const days = -1;
  let d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);

  document.cookie = `${name}=;path=/;expires=${d.toGMTString()};domain=${cookieDomain()}`;
};

const parseCookie = (docCookie) => {
  const cookies = docCookie
    .split(';')
    .map((s) => s.trim())
    .filter(Boolean)
    .reduce((res, next) => {
      const [k, v] = next.split('=');
      res[k.trim()] = v && v.trim ? v.trim() : '';
      return res;
    }, {});

  return cookies;
};

export const setUserConsentCookie = (value) => {
  const sslEnabled = window.location.protocol === 'https:';
  const maxAge = 60 * 60 * 24 * 365 * 20; // 20 years
  const secureVal = sslEnabled ? 'Secure' : '';
  document.cookie = `${userConsentCookie}=${value};path=/;max-age=${maxAge};${secureVal};domain=${cookieDomain()}`;
};

export const deleteUserConsentCookie = () => {
  deleteCookie(userConsentCookie);
};

export const getUserConsentCookieValue = () => {
  const cookies = parseCookie(document.cookie);
  const json = cookies[userConsentCookie];
  if (!json) {
    return {};
  }

  return JSON.parse(decodeURIComponent(json));
};
