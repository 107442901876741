import { BackendDataSource } from './backend-ds';

export class TrackingConsent {
  constructor({ backendApi = null, fetch = window.fetch, bannerConfig } = {}) {
    this.bannerConfig = bannerConfig;
    this.backendDataSource = new BackendDataSource({
      backendApi,
      fetch,
    });
  }

  setUserConsent(choices) {
    return this.backendDataSource.setUserConsent(choices);
  }

  resetUserConsent() {
    return this.backendDataSource.resetUserConsent();
  }

  getUserConsent() {
    return this.backendDataSource.getUserConsent();
  }

  acceptSelected(choices) {
    // new devise -- never opted in.
    // gt the optional selections.
    // No need to store / expose mandatory
    // categories
    if (!choices.version) {
      return {
        ...this.bannerConfig.modal.categories
          .filter((cat) => !cat.mandatory)
          .reduce((res, category) => {
            const required = !!choices[category.name];
            return { ...res, [category.name]: required };
          }, {}),
        version: this.bannerConfig.version,
      };
    } else {
      // Existing user, update the version to the new
      return { ...choices, version: this.bannerConfig.version };
    }
  }

  allCategoriesAccepted() {
    // No need to store / expose mandatory
    // categories
    return this.bannerConfig.modal.categories
      .filter((cat) => !cat.mandatory)
      .reduce(
        (res, category) => ({
          ...res,
          [category.name]: true,
        }),
        { version: this.bannerConfig.version }
      );
  }

  allCategoriesRejected() {
    return {};
  }

  // Force show via query params
  // ex. Privacy hub -> kununu.com?showUserConsentBanner=1
  shouldShowUserConsent(location) {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('showUserConsentBanner') === '1';
  }

  userConsentRequired(userConsent) {
    const optInVersion = userConsent.version;
    const currentLiveVersion = this.bannerConfig.version;

    return (
      !optInVersion || parseInt(optInVersion) < parseInt(currentLiveVersion)
    );
  }
}

export const updateChoice = (choices, categoryName, checked) => ({
  ...choices,
  [categoryName]: checked,
});
