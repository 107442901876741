import { Mixin } from '@xingternal/hops-mixin';
import urlJoin from 'url-join';
import debugFactory from '@xingternal/hops-debug';
import { pathToRegexp } from 'path-to-regexp';
import { join, trimTrailingSlash, ensureLeadingSlash } from 'pathifist';
import {
  allowedToLog,
  shouldBeTracked,
  trackFetchTiming,
  trackLoadTimings,
} from './logjam';

const debug = debugFactory('hops:logjam');

const sanitizePathname = (pathname) =>
  trimTrailingSlash(ensureLeadingSlash(pathname));

const getLogjamAction = (locations, basePath, rawPathname) => {
  const pathname = sanitizePathname(rawPathname);

  if (pathname === basePath) {
    return 'index';
  }

  const action = locations.find(
    (location) =>
      Array.isArray(pathToRegexp(join(basePath, location)).exec(pathname)) &&
      location
  );

  return action
    ? action.replace(/^\//, '').replace(/:/g, '$').replace(/\//g, '::')
    : undefined;
};

class HopsLogjamBrowserMixin extends Mixin {
  bootstrap() {
    const {
      logjam: { frontendBaseUrl, frontendHost, locations } = {},
      basePath,
    } = this.config;

    const { logjamRequestId, logjamAction, enableLogjam } =
      this.getServerData();

    if (!enableLogjam) {
      return;
    }

    this.frontendBaseUrl = frontendBaseUrl || frontendHost || '/';
    this.locations = locations || [];
    this.basePath = sanitizePathname(basePath);
    this.logjamRequestId = logjamRequestId;

    if (logjamRequestId && logjamAction && allowedToLog()) {
      const track = () => {
        setTimeout(() => {
          trackLoadTimings(
            urlJoin(this.frontendBaseUrl, '/logjam/page'),
            logjamRequestId,
            logjamAction
          );
        }, 0);
      };

      if (document.readyState === 'complete') {
        track();
      } else {
        window.addEventListener('load', track, false);
      }
    }
  }

  configureFetch(fetch) {
    const { enableLogjam } = this.getServerData();

    if (!enableLogjam) {
      return fetch;
    }

    return (...fetchArgs) => {
      const startTime = Date.now();

      return fetch(...fetchArgs).then((response) => {
        const endTime = Date.now();

        if (allowedToLog() && shouldBeTracked(response)) {
          trackFetchTiming(
            urlJoin(this.frontendBaseUrl, '/logjam/ajax'),
            response,
            startTime,
            endTime
          );
        }

        return response;
      });
    };
  }

  getWebVitalsHandler() {
    return ({ id, name, value }) => {
      const { enableLogjam } = this.getServerData();

      const { logjamRequestId } = this;

      if (!logjamRequestId || !enableLogjam) {
        debug('Skip logging web vitals because Logjam is not configured.');
        return;
      }

      const webVitalsEndpoint = urlJoin(
        this.frontendBaseUrl,
        '/logjam/webvitals'
      );
      const { pathname } = window.location;
      const logjamBaseAction = getLogjamAction(
        this.locations,
        this.basePath,
        pathname
      );

      if (typeof logjamBaseAction !== 'string') {
        debug(
          `Skipping reporting because ${pathname} is not valid for Logjam.`
        );
        return;
      }

      const action = `${logjamBaseAction}#get`;
      const metric = encodeURIComponent(name.toLowerCase());
      const payload = [
        `logjam_request_id=${encodeURIComponent(logjamRequestId)}`,
        `logjam_action=${encodeURIComponent(action)}`,
        `metrics[].id=${encodeURIComponent(id)}`,
        `metrics[].${metric}=${encodeURIComponent(value)}`,
      ].join('&');

      debug(payload);

      this.fetch(`${webVitalsEndpoint}?${payload}`, {
        method: 'POST',
        mode: 'no-cors',
        keepalive: true,
      });
    };
  }
}

export default HopsLogjamBrowserMixin;
