// @ts-check

import { createContext } from 'react';

/**
 * @typedef {import('@xingternal/hops-fetch').FetchContextInterface} FetchContextInterface
 */

/**
 * @type {typeof import('@xingternal/hops-fetch').FetchContext}
 */
const FetchContext = createContext(/** @type {FetchContextInterface} */ ({}));

FetchContext.displayName = 'Hops.Fetch';

export default FetchContext;
export const Provider = FetchContext.Provider;
export const Consumer = FetchContext.Consumer;
