import React, { useEffect, useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const Context = createContext({ userChoices: null });

export const CookieConsentProvider = ({ children }) => {
  const [userChoices, setUserChoices] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <Context.Provider
      value={{
        userChoices,
        setUserChoices,
        showModal,
        setShowModal,
        setShowSettings,
        showSettings,
      }}
    >
      {children}
    </Context.Provider>
  );
};

CookieConsentProvider.propTypes = {
  children: PropTypes.node,
};

export const useCookieConsentContext = () => useContext(Context);

export const useUserConsent = (category) => {
  const { userChoices: userChoicesContext } = useCookieConsentContext();
  const [userChoice, setUserChoice] = useState(null);

  useEffect(() => {
    if (userChoicesContext) {
      setUserChoice(userChoicesContext[category]);
    }
  }, [userChoicesContext]);

  return userChoice;
};

export const useShowBanner = () => {
  const { setShowModal } = useContext(Context);
  return () => {
    setShowModal(true);
  };
};

export const withShowBanner = (WrappedComponent) => {
  const WithShowBanner = (props) => {
    return (
      <Context.Consumer>
        {({ setShowModal }) => {
          const setShowModalFn = () => setShowModal(true);

          return <WrappedComponent showBanner={setShowModalFn} {...props} />;
        }}
      </Context.Consumer>
    );
  };

  WithShowBanner.displayName = `WithShowBanner(${
    WrappedComponent.displayName || 'Generic'
  })`;

  return WithShowBanner;
};

export const withUserConsent = (WrappedComponent, category) => {
  const WithUserConsent = (props) => {
    return (
      <Context.Consumer>
        {({ userChoices }) => {
          const canTrack = userChoices ? userChoices[category] : null;

          return <WrappedComponent canTrack={canTrack} {...props} />;
        }}
      </Context.Consumer>
    );
  };

  WithUserConsent.displayName = `withUserConsent(${
    WrappedComponent.displayName || 'Generic'
  })`;

  return WithUserConsent;
};
