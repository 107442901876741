// @ts-check

import { createElement, useContext } from 'react';

import FetchContext from './context';
import HopsResponseError from './hops-response-error';

/**
 * @type {typeof import('@xingternal/hops-fetch').withFetch}
 */
const withFetch = (WrappedComponent) =>
  function WithFetch(props) {
    const fetch = useFetch();

    return createElement(WrappedComponent, {
      ...props,
      fetch,
    });
  };

/**
 * @type {typeof import('@xingternal/hops-fetch').useFetch}
 */
const useFetch = () => {
  const { fetch } = useContext(FetchContext);

  return fetch;
};

/**
 * @type {typeof import('@xingternal/hops-fetch').withFetchJson}
 */
const withFetchJson = (WrappedComponent) =>
  function WithFetchJson(props) {
    const fetchJson = useFetchJson();

    return createElement(WrappedComponent, {
      ...props,
      fetchJson,
    });
  };

/**
 * @type {typeof import('@xingternal/hops-fetch').useFetchJson}
 */
const useFetchJson = () => {
  const { fetchJson } = useContext(FetchContext);

  return fetchJson;
};

export {
  withFetch,
  withFetchJson,
  useFetch,
  useFetchJson,
  HopsResponseError,
  FetchContext,
};
