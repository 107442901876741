import React, { useContext } from 'react';

import { Route, Switch, Link } from 'react-router-dom';

import { render, Miss, useConfig } from '@xingternal/hops';

import Fallback from '@xingternal/fallback';
import GlobalStyles from '@xingternal/global-styles';
import { XingFrameProvider } from '@xingternal/xing-frame-provider';
import withStaticData from '@xingternal/xing-frame-provider/withStaticData';
import navigationData from '@xingternal/xing-frame-provider/data/navigation';

import routes from './routes';
import CookieConsentContext, {
  CookieConsentProvider,
} from './contexts/CookieConsentContext';

import Demo from './demo';

const FrameProvider = withStaticData({ navigationData })(XingFrameProvider);

const App = () => {
  const { externalUrl, isPreviewMode } = useConfig();
  const [{ disableBanner }] = useContext(CookieConsentContext);

  const isTestEnvironment = externalUrl === 'http://application';

  // value below must be a function in order to enable
  // the "accept all" button in the consent checkbox settings layer
  const onSettingsAcceptAll = () => null;

  return (
    <FrameProvider
      userConsentSettings={{
        disableBanner: isTestEnvironment || disableBanner,
        onSettingsAcceptAll,
      }}
      internalPaths={['/language']}
      LinkComponent={Link}
    >
      <GlobalStyles>
        <Fallback>
          <Switch>
            {routes.map(({ path, renderFunction }) => (
              <Route exact key={path} path={path} render={renderFunction} />
            ))}
            {isPreviewMode === 'true' && (
              <Route key="demo" path="/_demo" component={Demo} />
            )}
            <Miss />
          </Switch>
        </Fallback>
      </GlobalStyles>
    </FrameProvider>
  );
};

const reducers = {
  root: (state = {}, _action) => state,
};

export default render(
  <CookieConsentProvider>
    <App />
  </CookieConsentProvider>,
  { redux: { reducers } }
);
