import React from 'react';
import PropTypes from 'prop-types';

import { IconCross } from '@xingternal/icons';

import styles from './Header.css';

const Header = ({ onClose }) => {
  return (
    <header className={styles.header}>
      <button className={styles.close} onClick={onClose}>
        <IconCross height={32} width={32} />
      </button>
    </header>
  );
};

Header.displayName = 'OmViewHeader';

Header.propTypes = {
  onClose: PropTypes.func,
};

export { Header };
