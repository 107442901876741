import { useMutation } from '@apollo/client';
import { useFetch } from '@xingternal/hops-fetch';

import { UPLOAD_FILE_APPLICATION } from '../constants';

import UPLOAD_REQUEST_MUTATION from './uploadRequestMutation.graphql';

const uploadFileToUrl = async (url, authToken, file, fetch) => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Upload-Offset': '0',
      'Tus-Resumable': '1.0.0',
      'Content-Type': 'application/offset+octet-stream',
    },
    body: file,
  });

  return !!response.ok;
};

const useUploadFile = () => {
  const [uploadRequestMutation] = useMutation(UPLOAD_REQUEST_MUTATION);
  const fetch = useFetch();

  const uploadFile = async file => {
    if (!file) return null;

    const { data } = await uploadRequestMutation({
      variables: {
        application: UPLOAD_FILE_APPLICATION,
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
      },
    });

    if (data && data.uploadRequest && data.uploadRequest.success) {
      const { id, url, authToken } = data.uploadRequest.success;

      if (url && authToken) {
        const fileIsUploaded = await uploadFileToUrl(
          url,
          authToken,
          file,
          fetch
        );

        if (fileIsUploaded) {
          return id;
        }
      }
    }

    return null;
  };

  return { uploadFile };
};

export default useUploadFile;
