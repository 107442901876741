import React from 'react';

import { importComponent } from '@xingternal/hops';

const Start = importComponent(() => import('./Pages/Start/Start'));
const Signup = importComponent(() => import('./Pages/Signup/Signup'));
const Language = importComponent(() => import('./Pages/Language/Language'));
const FirstLogin = importComponent(() =>
  import('./Pages/FirstLogin/FirstLogin')
);
const Confirmation = importComponent(() =>
  import('./Pages/Confirmation/Confirmation')
);
const Completion = importComponent(() =>
  import('./Pages/Completion/Completion')
);
const Invitation = importComponent(() =>
  import('./Pages/Invitation/Invitation')
);

const routes = [
  {
    path: '/:locale([a-z]{0,2})',
    renderFunction: props => <Start {...props} />,
  },
  {
    path: '/start',
    renderFunction: props => <Start {...props} />,
  },
  {
    path: '/start/signup',
    renderFunction: props => <Signup {...props} />,
  },
  {
    path: '/start/first_login',
    renderFunction: props => <FirstLogin {...props} />,
  },
  {
    path: '/start/confirmation',
    renderFunction: props => <Confirmation {...props} />,
  },
  {
    path: '/start/completion',
    renderFunction: props => <Completion {...props} />,
  },
  {
    path: '/start/invitation',
    renderFunction: props => <Invitation {...props} />,
  },
  {
    path: '/language',
    renderFunction: props => <Language {...props} />,
  },
];

export default routes;
