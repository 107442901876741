import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Headline, BodyCopy } from '@xingternal/typography';
import { FormField } from '@xingternal/text-field';
import { Checkbox } from '@xingternal/checkbox';
import { IconApple } from '@xingternal/icons';

import IconGoogle from './assets/IconGoogle';
import FormMessage from './../form-message/FormMessage';
import RegisterViaCvSection from './../register-via-cv-section/RegisterViaCvSection';
import PasswordInput from './../password-input/PasswordInput';
import PasswordStrengthMeter from './../password-strength-meter/PasswordStrengthMeter';

import {
  FakeInput,
  InputActions,
  InputWrapper,
  HiddenWrapper,
  SubmitButton,
  Label,
  GoogleButton,
  AppleButton,
} from './../styled';

import RecaptchaForm from './RecaptchaForm';
import Recaptcha from './Recaptcha';

import messages from './messages';

const clearError = (fieldName, errors) =>
  Object.keys(errors).reduce((acc, field) => {
    if (field === fieldName) return acc;
    acc[field] = errors[field];
    return acc;
  }, {});

const View = ({
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  touched,
  submitCount,
  validateForm,
  values,
  intl,
  googleSiteKey,
  showRecaptcha,
  setErrors,
  setFieldValue,
  setValues,
  setTouched,
  language,
  headlineMessage,
  ctaMessage,
  submitForm,
  enabledCheck,
  hideHeadline,
  neededFields,
  emailOnly,
  emailDomainError,
  setEmailDomainError,
  onSubmitValidateErrors,
  secondaryEmailError,
  setSecondaryEmailError,
  cvUploadConfig,
  externalUrl,
  enableGoogleSignup,
  enableAppleSignup,
  signupChannel,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [prevSubmitErrors, setPrevSubmitErrors] = useState([]);

  const handleResetCvUpload = () => {
    setFieldValue('parsedCvId', '');
  };

  const isTouched = property => {
    const changed = touched[property] && values[property] !== '';
    const submitted = submitCount > 0;
    return changed || submitted;
  };

  const handleParsedCvDataReceived = async ({
    // email,
    // firstName,
    // lastName,
    id,
  }) => {
    try {
      await setValues({
        ...values,
        // ...(email && { email }),
        // ...(firstName && { firstName }),
        // ...(lastName && { lastName }),
        ...(id && { parsedCvId: id }),
      });
      await setTouched(
        {
          ...touched,
          // ...(email && { email: false }),
          // ...(firstName && { firstName: false }),
          // ...(lastName && { lastName: false }),
          ...(id && { parsedCvId: false }),
        },
        false
      );
      await validateForm();

      return !!(
        // email || firstName || lastName ||
        id
      );
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (!enabledCheck) return;
    setIsDisabled(!enabledCheck(values));
  }, [values]);

  /**
   * We use this side effect to help with error tracking in
   * welcome-frontend. While `onSubmitValidateErrors` could
   * be used for other things, it's not very likely. This can be removed
   * if we decide to skip error tracking again. It's _not_ needed
   * for the form logic to work properly.
   *
   * The goal of this rather complex logic is to only track errors
   * that are visually presented to the user (the backend always responds
   * with validation for all fields).
   */
  useEffect(() => {
    if (typeof onSubmitValidateErrors !== 'function') return;

    const submitErrors = Object.keys(errors)
      // filter out errors related to untouched fields
      .filter(property => isTouched(property))
      // convert the error object to an array of objects [{ fieldName, error }]
      .map(property => ({ fieldName: property, error: errors[property] }));

    // check if errors changed
    const haveErrorsChanged = submitErrors
      .map(({ fieldName, error }) => {
        // can I find a previous error that's already related to this field?
        const prevSubmitError = prevSubmitErrors.find(
          prevError => prevError.fieldName === fieldName
        );

        if (prevSubmitError) {
          // We found a previous error. Did the error key change?
          return prevSubmitError.error !== error;
        } else {
          // Did not find a previous error. We have a new error
          return true;
        }
      })
      .some(fieldChanged => fieldChanged);

    // Only execute the call back if we have new errors
    if (haveErrorsChanged) {
      onSubmitValidateErrors(submitErrors);
    }

    // store the errors for the next change of errors
    setPrevSubmitErrors(submitErrors);
  }, [errors, submitCount]);

  if (showRecaptcha) {
    return (
      <RecaptchaForm
        setFieldValue={setFieldValue}
        googleSiteKey={googleSiteKey}
        onSubmit={submitForm}
        language={language}
      />
    );
  }

  const maybeError = property => {
    if (!errors[property] || !isTouched(property)) {
      return null;
    }

    return translateError(errors[property]);
  };

  const translateError = error => {
    if (!error) return null;
    return typeof error === 'string'
      ? intl.formatMessage({ id: error })
      : intl.formatMessage(error);
  };

  const handleInputChange = e => {
    handleChange(e);

    const fieldName = e.target.name;
    setErrors(clearError(fieldName, errors));
  };

  const handleEmailChange = e => {
    setEmailDomainError(false);
    setFieldValue('skipEmailDomainCheck', false);
    setSecondaryEmailError(false);
    setFieldValue('checkSecondaryFields', true);
    handleInputChange(e);
  };

  return (
    <React.Fragment>
      {!hideHeadline && (
        <Headline size="xxlarge" as="h2">
          {intl.formatMessage(headlineMessage || messages.headline)}
        </Headline>
      )}

      {cvUploadConfig && cvUploadConfig.source && cvUploadConfig.flowIdentifier && (
        <RegisterViaCvSection
          handleParsedCvDataReceived={handleParsedCvDataReceived}
          handleResetCvUpload={handleResetCvUpload}
          cvUploadConfig={cvUploadConfig}
        />
      )}

      <form method={'POST'} onSubmit={handleSubmit} autoComplete={'off'}>
        <FakeInput name={'email'} />
        <FakeInput type={'password'} name={'password'} />
        {!emailOnly && neededFields.includes('firstName') && (
          <InputWrapper>
            <FormField
              autoFocus
              size="medium"
              error={maybeError('firstName')}
              helperText={maybeError('firstName')}
              name={'firstName'}
              id={'firstName'}
              onBlur={handleBlur}
              onChange={handleInputChange}
              placeholder={intl.formatMessage(messages.firstNameLabel)}
              value={values.firstName}
              data-qa={'firstName'}
            />
          </InputWrapper>
        )}
        {!emailOnly && neededFields.includes('lastName') && (
          <InputWrapper>
            <FormField
              size="medium"
              error={maybeError('lastName')}
              helperText={maybeError('lastName')}
              placeholder={intl.formatMessage(messages.lastNameLabel)}
              name={'lastName'}
              id={'lastName'}
              onBlur={handleBlur}
              onChange={handleInputChange}
              value={values.lastName}
              data-qa={'lastName'}
            />
          </InputWrapper>
        )}
        {cvUploadConfig && cvUploadConfig.source && cvUploadConfig.flowIdentifier && (
          <HiddenWrapper>
            <FormField
              size="medium"
              name={'parsedCvId'}
              id={'parsedCvId'}
              onChange={handleInputChange}
              value={values.parsedCvId}
              data-qa={'parsedCvId'}
            />
          </HiddenWrapper>
        )}
        {neededFields.includes('email') && (
          <InputWrapper>
            <FormField
              autoFocus={emailOnly}
              size="medium"
              error={
                maybeError('email') ||
                (emailDomainError && translateError(errors.emailDomain))
              }
              helperText={
                maybeError('email') ||
                (emailDomainError && translateError(errors.emailDomain))
              }
              name={'email'}
              id={'email'}
              onBlur={handleBlur}
              onChange={handleEmailChange}
              placeholder={intl.formatMessage(messages.emailLabel)}
              value={values.email}
              data-qa={'email'}
            />
          </InputWrapper>
        )}
        {neededFields.includes('password') && (
          <InputWrapper>
            <PasswordInput
              medium
              error={maybeError('password')}
              name={'password'}
              id={'password'}
              onBlur={handleBlur}
              onChange={handleInputChange}
              placeholder={intl.formatMessage(messages.passwordLabel)}
              value={values.password}
              data-qa={'password'}
            />
            <PasswordStrengthMeter
              password={values.password}
              error={maybeError('password')}
            />
          </InputWrapper>
        )}
        {neededFields.includes('gRecaptchaResponse') && (
          <Recaptcha
            setFieldValue={setFieldValue}
            onSubmit={submitForm}
            googleSiteKey={googleSiteKey}
            language={language}
          />
        )}
        {neededFields.includes('tandcCheck') && (
          <InputActions>
            <BodyCopy size="medium" noMargin style={{ margin: 0 }}>
              <FormattedMessage
                {...messages.tandcDescription}
                values={{
                  tandc: (
                    <a href={'/terms'} target={'_blank'}>
                      <FormattedMessage {...messages.tandcLabel} />
                    </a>
                  ),
                  dataprotection: (
                    <a
                      href={intl.formatMessage(messages.dataprotectionLink)}
                      target={'_blank'}
                    >
                      <FormattedMessage {...messages.dataprotectionLabel} />
                    </a>
                  ),
                }}
              />
            </BodyCopy>
          </InputActions>
        )}
        <FormMessage error={translateError(errors.base)} />
        {emailDomainError && (
          <React.Fragment>
            <FormMessage
              notice={intl.formatMessage(messages.emailDomainError)}
            />
            <InputWrapper>
              <Checkbox
                checked={values.skipEmailDomainCheck}
                name={'skipEmailDomainCheck'}
                id={'skipEmailDomainCheck'}
                onChange={event => {
                  setFieldValue('skipEmailDomainCheck', event.target.checked);
                  setTimeout(validateForm, 0);
                }}
              />
              <Label htmlFor={'skipEmailDomainCheck'}>
                <FormattedMessage
                  {...messages.skipEmailDomainCheck}
                  values={{ email: <b>{values.email}</b> }}
                />
              </Label>
            </InputWrapper>
          </React.Fragment>
        )}
        {secondaryEmailError && (
          <InputWrapper>
            <FormMessage
              notice={intl.formatMessage(messages.secondaryEmailError)}
            />

            <Checkbox
              checked={!values.checkSecondaryFields}
              name={'checkSecondaryFields'}
              id={'checkSecondaryFields'}
              onChange={event => {
                setFieldValue('checkSecondaryFields', !event.target.checked);
                setTimeout(validateForm, 0);
              }}
            />
            <label htmlFor={'checkSecondaryFields'}>
              <FormattedMessage
                {...messages.skipSecondaryEmailCheck}
                values={{ email: <b>{values.email}</b> }}
              />
            </label>
          </InputWrapper>
        )}
        <SubmitButton
          variant="primary"
          size="medium"
          loading={isSubmitting}
          type={'submit'}
          disabled={isDisabled}
          data-qa={'register-submit-button'}
        >
          {intl.formatMessage(ctaMessage || messages.submitLabel)}
        </SubmitButton>
      </form>
      {enableGoogleSignup && !cvUploadConfig && (
        <GoogleButton
          variant="secondary"
          size="medium"
          icon={IconGoogle}
          href={`${externalUrl}/signup/oauth?caller=signup_page&signup_channel=${signupChannel ?? 'oauth_google' }`}
          onClick={() => {
            ttt.pageview(
              PROP_CHANNEL,
              'wbm/Welcome/start/index/google',
              {},
              {
                PropTrackAction: 'lp_google_click',
              }
            );
          }}
        >
          <FormattedMessage
            id="WELCOME_STARTPAGE_PROCEED_WITH_GOOGLE_BUTTON"
            defaultMessage="Continue with Google"
          />
        </GoogleButton>
      )}
      {enableAppleSignup && !cvUploadConfig && (
        <AppleButton
          variant="secondary"
          size="medium"
          icon={IconApple}
          href={`${externalUrl}/signup/oauth/apple?caller=signup_page&signup_channel=${signupChannel ?? 'oauth_apple' }`}
          onClick={() => {
            ttt.pageview(
              PROP_CHANNEL,
              'wbm/Welcome/start/index/apple',
              {},
              {
                PropTrackAction: 'lp_apple_click',
              }
            );
          }}
        >
          <FormattedMessage
            id="WELCOME_STARTPAGE_PROCEED_WITH_APPLE_BUTTON"
            defaultMessage="Continue with Apple"
          />
        </AppleButton>
      )}
    </React.Fragment>
  );
};

View.propTypes = {
  errors: PropTypes.object,
  googleSiteKey: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  intl: PropTypes.any,
  isSubmitting: PropTypes.bool,
  language: PropTypes.string,
  hideHeadline: PropTypes.bool,
  setErrors: PropTypes.func,
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
  setTouched: PropTypes.func,
  showRecaptcha: PropTypes.bool,
  submitCount: PropTypes.number,
  submitForm: PropTypes.func,
  touched: PropTypes.any,
  validateForm: PropTypes.func,
  values: PropTypes.object,
  headlineMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  ctaMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  enabledCheck: PropTypes.func,
  neededFields: PropTypes.arrayOf(PropTypes.string),
  emailOnly: PropTypes.bool,
  emailDomainError: PropTypes.bool,
  setEmailDomainError: PropTypes.func,
  secondaryEmailError: PropTypes.bool,
  setSecondaryEmailError: PropTypes.func,
  onSubmitValidateErrors: PropTypes.func,
  cvUploadConfig: PropTypes.shape({
    source: PropTypes.string,
    flowIdentifier: PropTypes.string,
  }),
};

export default injectIntl(View);
