import { assign } from './../lib';
const getPerformanceMetrics = window => {
  const performance =
    window.performance ||
    window.webkitPerformance ||
    window.mozPerformance ||
    window.msPerformance;

  if (!performance || !performance.timing) {
    return {};
  }

  const timing = performance.timing;
  const now = new Date().getTime();

  const times = {
    network: timing.responseEnd || now,
    pageLoad: timing.domContentLoadedEventEnd || now,
    pageSetup: timing.loadEventStart || now,
    loadEvent: now,
  };

  let prevTime = timing.navigationStart;

  const deltas = Object.keys(times)
    .map(name => {
      const time = times[name];
      const delta = time - prevTime;
      prevTime = time;

      return {
        [name]: delta,
      };
    })
    .reduce((prev, current) => assign(prev, current), {});

  return deltas;
};

export default getPerformanceMetrics;
