// @ts-check

/**
 * @type {typeof import('@xingternal/hops-fetch').HopsResponseError}
 */
class HopsResponseError extends Error {
  /**
   * @param {string} message
   * @param {any} response
   * @param {any} jsonResponse
   */
  constructor(message, response, jsonResponse) {
    super(message);

    this.name = this.constructor.name;
    this.response = response;
    this.jsonResponse = jsonResponse;

    // Workaround to make `instanceof HopsResponseError` work in transpiled ES5
    Object.setPrototypeOf(this, HopsResponseError.prototype);
  }
}

module.exports = HopsResponseError;
