import uuid from '../uuid';
import { buildMessage } from './helpers';
import subscriber from './subscriber';

const publisher = (window, target, origin = '*') => {
  return (action, payload, options = {}) => {
    const id = options.id || uuid();
    const message = buildMessage({ id, action, payload });

    target.postMessage(JSON.stringify(message), '*');

    const {
      fireAndForget = false,
      timeout = 10000,
      fullResponse = false,
    } = options;

    if (fireAndForget === true) {
      return Promise.resolve();
    } else {
      return new Promise((resolve, reject) => {
        const subscribe = subscriber(window);

        const timeoutHandler = setTimeout(() => {
          reject(new Error(`response for '${action}' has timed out`));
        }, timeout);

        const unsubscribe = subscribe(
          `response:${action}:${id}`,
          response => {
            unsubscribe();

            if (fullResponse) {
              resolve(response);
            } else {
              const { error, payload } = response;

              if (error) {
                reject(error);
              } else {
                resolve(payload);
              }
            }

            clearTimeout(timeoutHandler);

            return true;
          },
          { fullResponse: true }
        );
      });
    }
  };
};

export default publisher;
