import React from 'react';
import PropTypes from 'prop-types';

import { FormMessageBanner } from './../styled';

const FormMessage = ({ notice, error }) => {
  const message = error || notice;

  if (!message) return null;

  const variant = error ? 'error' : 'info';
  const bannerProps = {
    display: 'inline',
    variant,
  };

  return (
    <FormMessageBanner {...bannerProps}>
      {typeof message === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: message }} /> // eslint-disable-line
      ) : (
        message
      )}
    </FormMessageBanner>
  );
};

FormMessage.propTypes = {
  notice: PropTypes.string,
  error: PropTypes.string,
};

export default FormMessage;
