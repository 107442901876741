// @ts-nocheck

import * as React from 'react';
import { withServerData, useServerData } from '@xingternal/hops-react';

/**
 * @type {typeof import('@xingternal/hops').withLoginState}
 */
export const withLoginState = (Component) => {
  return withServerData(({ serverData: { loginState }, ...props }) => (
    // @ts-ignore
    <Component {...props} loginState={loginState} />
  ));
};

/**
 * @type {typeof import('@xingternal/hops').useLoginState}
 */
export const useLoginState = () => {
  const serverData = useServerData();
  return serverData['loginState'];
};
