// @ts-check

/**
 * @type {typeof import('@xingternal/hops-user-auth').resolvers}
 */
const resolvers = {
  Query: {
    viewer: (root) => root || {},
  },
  Viewer: {
    id: ({ id } = {}, _args, context) =>
      id || `${context.res.locals.userId || 0}.123abc`,
    loginState: ({ loginState } = {}, _args, context) => {
      return loginState || context.res.locals.loginState || 'LOGGED_OUT';
    },
  },
};

module.exports = resolvers;
