export const isXingbox = () => {
  return window.location.host.match(/kenv.xing.com/);
};

export const isXingPreview = () => {
  return window.location.host.match(/preview.xing.com/);
};

export const newConsentLocales = ['en', 'de', 'fr', 'es'];

export const getLanguage = (lang, doc, location) => {
  if (lang) {
    return lang;
  }

  const sslEnabled = location.protocol === 'https:';
  const cookieName = sslEnabled ? 'language' : 'language_insecure';

  const cookieValuePair = doc.cookie
    .split(';')
    .map((item) => item.trim())
    .find((item) => item.startsWith(`${cookieName}=`));

  const cookieValue = cookieValuePair && cookieValuePair.split('=')[1];
  let cookieLang;
  let browserLang;

  cookieLang =
    cookieValue && newConsentLocales.includes(cookieValue) ? cookieValue : null;
  browserLang = newConsentLocales.find(
    (l) => navigator.language.indexOf(l) > -1
  );

  return cookieLang || browserLang || 'en';
};

export const shouldDisableOverlay = (
  disableOverlayOption,
  disableOverlayConfig
) => {
  if (disableOverlayOption === false) {
    return false;
  }

  return disableOverlayOption || disableOverlayConfig;
};
