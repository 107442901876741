import { getSecondLevelDomain } from './';

const getTrackingEndpointUrl = (config, env, path) => {
  let { host } = config;
  const secondLevelDomain = getSecondLevelDomain();
  const nonAntsSecondLevelDomains = [
    'xing.com',
    'csod.com',
    'onlyfy.dev',
    'localhost',
    'test.local',
    null,
  ];
  if (
    secondLevelDomain &&
    !nonAntsSecondLevelDomains.includes(secondLevelDomain)
  ) {
    host =
      env === 'production'
        ? `ants.${secondLevelDomain}`
        : `preview-ants.${secondLevelDomain}`;
  }
  return `https://${host}${path}`;
};

export default getTrackingEndpointUrl;
