import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { BodyCopy, Headline } from '@xingternal/typography';
import { IconUpload } from '@xingternal/icons';
import { ttt } from '@xingternal/malt-tracking';

import useUploadFile from './use-upload-file';
import useGetParsedCvData from './use-get-parsed-cv-data';
import messages from './messages';
import {
  ERROR_MESSAGES,
  MAX_ALLOWED_CV_SIZE_IN_MB,
  PROP_CHANNEL,
  ACCEPTED_FILE_FORMATS,
} from './constants';

import {
  Container,
  Content,
  StyledButton,
  HelperText,
  StyledStatusBanner,
  UploadInput,
  StyledContentBanner,
  ResultsContainer,
  StyledIconFilePDF,
  StyledProgressBar,
} from './styled';

export const convertToMB = size => (size / 1024 / 1024).toFixed(2);

const RegisterViaCvSection = ({
  handleParsedCvDataReceived,
  handleResetCvUpload,
  cvUploadConfig,
}) => {
  const [file, setFile] = useState(null);
  const fileUploadInput = useRef(null);

  const [fileUploadError, setFileUploadError] = useState(null);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);

  const { uploadFile } = useUploadFile();
  const { getParsedCvData } = useGetParsedCvData();

  const trackError = () => {
    ttt.event('PropTrackAction', {
      PropChannel: PROP_CHANNEL,
      PropTrackAction: 'losp_register_via_cv_upload_error',
    });
  };

  const onFileSelected = async selectedFile => {
    try {
      const uploadId = await uploadFile(selectedFile);
      if (!uploadId) {
        throw Error('uploadId could not be fetched');
      }

      const parsedCvData = await getParsedCvData(
        uploadId,
        cvUploadConfig.source,
        cvUploadConfig.flowIdentifier,
      );
      if (!parsedCvData) {
        throw Error('parsedCvData could not be fetched');
      }

      const cvDataHandled = await handleParsedCvDataReceived(parsedCvData);

      ttt.event('PropTrackAction', {
        PropChannel: PROP_CHANNEL,
        PropTrackAction: 'losp_register_via_cv_upload_success',
        PropContextDimension1: `losp_resgister_via_cv_prefill_${
          cvDataHandled ? 'success' : 'error'
        }`,
      });

      setFileUploadLoading(false);
      setFileUploadError(null);
      setFileUploadSuccess(true);
    } catch (e) {
      trackError();

      setFileUploadLoading(false);
      setFileUploadError(ERROR_MESSAGES.common);
      setFileUploadSuccess(false);
      setFile(null);
    }
  };

  const resetFileUpload = () => {
    ttt.event('PropTrackAction', {
      PropChannel: PROP_CHANNEL,
      PropTrackAction: 'losp_register_via_cv_remove_click',
    });

    setFileUploadLoading(false);
    setFileUploadError(null);
    setFileUploadSuccess(false);
    setFile(null);

    handleResetCvUpload && handleResetCvUpload();
  };

  const handleErrorBannerClose = () => setFileUploadError(null);

  const handleFileSelect = async event => {
    setFileUploadLoading(true);
    setFileUploadError(null);
    setFileUploadSuccess(false);
    setFile(null);

    try {
      const files =
        (event.dataTransfer && event.dataTransfer.files) || event.target.files;

      if (files && files.length >= 1) {
        const file = Array.from(files).pop();
        const fileSize = convertToMB(file.size);
        const fileExtension = `.${(file.name || '').split('.').pop()}`;
        const isFileSizeError = fileSize > MAX_ALLOWED_CV_SIZE_IN_MB;
        const isFileFormatError =
          !fileExtension || !ACCEPTED_FILE_FORMATS.includes(fileExtension);

        if (isFileSizeError || isFileFormatError) {
          ttt.event('PropTrackAction', {
            PropChannel: PROP_CHANNEL,
            PropTrackAction: `losp_register_via_cv_upload_error_${
              isFileSizeError ? 'filesize' : 'format'
            }`,
          });

          setFileUploadLoading(false);
          setFileUploadError(ERROR_MESSAGES.badFile);
        } else {
          setFile(file);
          await onFileSelected(file);
        }
      }
    } catch (e) {
      trackError();

      setFileUploadLoading(false);
      setFileUploadError(ERROR_MESSAGES.common);
      setFile(null);
    }
  };

  const selectFile = event => {
    if (event) {
      event.preventDefault();
    }

    ttt.event('PropTrackAction', {
      PropChannel: PROP_CHANNEL,
      PropTrackAction: 'losp_register_via_cv_upload_click',
    });

    fileUploadInput.current && fileUploadInput.current.click();
  };

  return (
    <>
      <Container>
        <Headline size="medium">
          <FormattedMessage {...messages.headlineDefault} />
        </Headline>
        <Content>
          <BodyCopy size="small" noMargin>
            <FormattedMessage {...messages.descriptionDefault} />
          </BodyCopy>
        </Content>
        <div>
          <UploadInput
            type="file"
            tabIndex="-1"
            ref={fileUploadInput}
            name="cv-upload-input"
            multiple={false}
            accept={ACCEPTED_FILE_FORMATS}
            onChange={handleFileSelect}
          />
          {!fileUploadSuccess && !fileUploadLoading ? (
            <>
              <StyledButton
                variant="secondary"
                size="small"
                icon={IconUpload}
                onClick={selectFile}
              >
                <FormattedMessage {...messages.buttonLabel} />
              </StyledButton>
              <HelperText size="xsmall" noMargin>
                <FormattedMessage {...messages.fileInfoLabel} />
              </HelperText>
            </>
          ) : (
            <StyledContentBanner
              display="inline"
              noPadding
              handleOnClose={fileUploadSuccess ? resetFileUpload : null}
            >
              <StyledIconFilePDF />
              <ResultsContainer>
                <BodyCopy fontWeight="bold" noMargin>
                  {file ? file.name : ''}
                </BodyCopy>
                {fileUploadLoading ? (
                  <StyledProgressBar variant="indeterminate" />
                ) : (
                  <BodyCopy size="small" noMargin>
                    {`${convertToMB(file.size)} MB`}
                  </BodyCopy>
                )}
              </ResultsContainer>
            </StyledContentBanner>
          )}
        </div>
      </Container>
      {fileUploadError && (
        <StyledStatusBanner
          variant="error"
          display="inline"
          handleOnClose={handleErrorBannerClose}
        >
          <FormattedMessage
            {...messages[fileUploadError]}
            values={{ fileName: file ? file.name : '' }}
          />
        </StyledStatusBanner>
      )}
    </>
  );
};

RegisterViaCvSection.propTypes = {
  handleParsedCvDataReceived: PropTypes.func,
  handleResetCvUpload: PropTypes.func,
  cvUploadConfig: PropTypes.shape({
    source: PropTypes.string,
    flowIdentifier: PropTypes.string,
  }),
};

export default RegisterViaCvSection;
