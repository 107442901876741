import { defineMessages } from 'react-intl';

export default defineMessages({
  headlineDefault: {
    id: 'WELCOME_REGISTRATION_VIA_CV_HEADLINE_DEFAULT',
    defaultMessage: 'Lass Dich von Recruiter·innen finden',
  },
  headlineSuccess: {
    id: 'WELCOME_REGISTRATION_VIA_CV_HEADLINE_SUCCESS',
    defaultMessage: 'Dein Lebenslauf',
  },
  descriptionDefault: {
    id: 'WELCOME_REGISTRATION_VIA_CV_DESCRIPTION_DEFAULT',
    defaultMessage:
      'Lade jetzt Deinen Lebenslauf hoch und lass Dir Job-Vorschläge von HR-Expert·innen zusenden.',
  },
  buttonLabel: {
    id: 'WELCOME_REGISTRATION_VIA_CV_BUTTON_LABEL',
    defaultMessage: 'Datei auswählen und hochladen',
  },
  fileInfoLabel: {
    id: 'WELCOME_REGISTRATION_VIA_CV_FILE_INFO_LABEL',
    defaultMessage:
      'Format: PDF-, Word- (.docx) oder Pages-Dokument (max. 100 MB)',
  },
  commonErrorLabel: {
    id: 'WELCOME_REGISTRATION_VIA_CV_COMMON_ERROR_LABEL',
    defaultMessage: "Das hat leider nicht geklappt. Versuch's bitte noch mal.",
  },
  badFileErrorLabel: {
    id: 'WELCOME_REGISTRATION_VIA_CV_BAD_FILE_ERROR_LABEL',
    defaultMessage:
      'Wir konnten die Datei {fileName} nicht laden. Das kann daran liegen, dass sie zu groß ist oder das falsche Format hat.',
  },
});
