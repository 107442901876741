import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@xingternal/button';
import { Headline } from '@xingternal/typography';
import cx from 'classnames';

import { UserChoicesTypes, ModalConfigurationTypes } from '../types';

import CategoryPanel from './CategoryPanel';
import checkboxStyles from './checkbox.css';

const CheckboxSettings = ({
  resources,
  userChoices,
  setUserChoices,
  acceptChoices,
  handleAcceptAll,
}) => (
  <div className={checkboxStyles.settingsContainer} id={'consent-settings'}>
    <div className={checkboxStyles.actionButtonContainer}>
      <Button
        variant="primary"
        size="medium"
        className={cx(
          checkboxStyles.acceptAllButton,
          'consent-banner-button-accept-all-settings'
        )}
        onClick={handleAcceptAll}
      >
        {resources.acceptAllButton}
      </Button>

      <Button
        className={cx(
          checkboxStyles.checkboxButton,
          'consent-banner-button-accept-overlay'
        )}
        id={'checkbox-accept-button'}
        size="medium"
        variant="secondary"
        onClick={() => acceptChoices(userChoices)}
      >
        {resources.acceptSelectedButton}
      </Button>
    </div>

    <div className={checkboxStyles.subtitleWrapper}>
      <Headline
        id={'consent-settings-subtitle'}
        className={cx(
          checkboxStyles.checkboxSubtitle,
          'consent-banner-overlay-subtitle'
        )}
        as="h2"
      >
        {resources.subTitle}
      </Headline>
    </div>

    <div id="consent-categories">
      {resources.categories.map((category) => {
        return (
          <CategoryPanel
            key={category.name}
            category={category}
            userChoices={userChoices}
            setUserChoices={setUserChoices}
          />
        );
      })}
    </div>
    <div className={checkboxStyles.actionButtonContainer}>
      <Button
        variant="primary"
        size="medium"
        className={cx(
          checkboxStyles.acceptAllButton,
          'consent-banner-button-accept-all-settings'
        )}
        onClick={handleAcceptAll}
      >
        {resources.acceptAllButton}
      </Button>

      <Button
        className={cx(
          checkboxStyles.checkboxButton,
          'consent-banner-button-accept-overlay'
        )}
        size="medium"
        variant="secondary"
        onClick={() => acceptChoices(userChoices)}
      >
        {resources.acceptSelectedButton}
      </Button>
    </div>
  </div>
);

CheckboxSettings.propTypes = {
  resources: ModalConfigurationTypes,
  userChoices: UserChoicesTypes,
  setUserChoices: PropTypes.func,
  acceptChoices: PropTypes.func.isRequired,
  handleAcceptAll: PropTypes.func.isRequired,
};

export default CheckboxSettings;
