require('cross-fetch/polyfill');

const HopsResponseError = require('./hops-response-error');

function fetchJson(input, { fetch, ...options } = {}) {
  const request = new Request(input, options);

  request.headers.set('accept', 'application/json');

  /**
   * We should set the content-type header only if we are sending data in order
   * to perform simple requests in a CORS environment
   * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
   *
   * We should set the content-type header to application/json only if there is
   * no header present already in order to have backwards compatible behaviour
   */

  if (
    ['POST', 'PUT', 'PATCH'].includes(request.method) &&
    !request.headers.get('content-type')
  ) {
    request.headers.set('content-type', 'application/json');
  }

  return (fetch || global.fetch)(request).then((res) => {
    if (res.status === 204) {
      return null;
    }

    return res
      .json()
      .then((json) => {
        if (!res.ok) {
          throw new HopsResponseError(
            `Server responded with status ${res.status} ${res.statusText}`,
            res,
            json
          );
        }

        return json;
      })
      .catch((error) => {
        if (!(error instanceof HopsResponseError)) {
          error = new HopsResponseError(error, res);
        }

        return Promise.reject(error);
      });
  });
}

module.exports = fetchJson;
