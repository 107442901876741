import 'cross-fetch/polyfill';
import querystring from 'querystring';

import config from 'hops-config';

const allowedToLog = (location) => {
  return (
    typeof location !== 'undefined' &&
    typeof location.hostname !== 'undefined' &&
    !location.hostname.startsWith('admin') &&
    location.hostname !== 'localhost'
  );
};

const sendFetchTiming = (start, end, response) => {
  const Image = global.Image;
  const logJamBaseUrl = config.logJamBaseUrl;
  if (
    Image &&
    typeof logJamBaseUrl === 'string' &&
    allowedToLog(global.location)
  ) {
    new Image().src =
      `${logJamBaseUrl}/ajax?` +
      querystring.stringify({
        logjam_request_id: response.headers.get('X-Logjam-Request-Id') || false,
        logjam_action: response.headers.get('X-Logjam-Request-Action') || false,
        rts: [start, end].join(','),
        url: response.url,
        v: 1,
        _: Date.now(),
      });
  }
};

export default (...args) => {
  if (typeof window !== 'undefined') {
    const start = Date.now();
    return global.fetch(...args).then((response) => {
      const end = Date.now();
      sendFetchTiming(start, end, response);
      return response;
    });
  } else {
    return global.fetch(...args);
  }
};
