import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormMessage from './../form-message/FormMessage';

import messages from './messages';

const partnerLink = (name, website) => <a href={website}>{name}</a>;

const PartnerNotice = ({ partner }) => {
  if (!partner) return null;

  return (
    <FormMessage
      notice={
        <FormattedMessage
          {...messages.partnerAuthIntroduction}
          values={{
            application: partnerLink(partner.name, partner.website),
          }}
        />
      }
    />
  );
};

export default PartnerNotice;
