const getWhitelistedSearchParams = url => {
  const paramsWhitelist = ['sc_cmp', 'sc_o'];
  const filteredUrl = new URL(url);

  for (const key of Array.from(filteredUrl.searchParams.keys())) {
    if (!paramsWhitelist.includes(key.toLowerCase())) {
      filteredUrl.searchParams.delete(key);
    }
  }

  return filteredUrl.searchParams.toString() || undefined;
};

export default getWhitelistedSearchParams;
