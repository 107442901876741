import config from 'hops-config';

import fetchWithCsrf from './fetch-with-csrf';
import MaltResponseError from './MaltResponseError';

const DEFAULT_OPTIONS = {
  credentials: 'include',
};

let cache = {};

const getUrl = (path) =>
  path.indexOf('/') === 0 ? `${config.apiHost || ''}${path}` : path;

const addToCache = (url, promiseOrObject, maxAge) => {
  const promise =
    promiseOrObject instanceof Promise
      ? promiseOrObject
      : Promise.resolve(promiseOrObject);

  cache[url] = {
    time: Date.now(),
    promise,
  };
  if (maxAge) {
    cache[url].maxAge = maxAge;
  }
};

const fillCache = (cacheData = {}) => {
  if (cacheData) {
    Object.keys(cacheData).forEach((key) => addToCache(key, cacheData[key]));
  }
};

const removeFromCache = (url) => {
  delete cache[url];
};

const resetCache = () => {
  cache = {};
};

const collectGarbage = (t) => {
  Object.keys(cache).forEach((key) => {
    if (cache[key].time + (cache[key].maxAge || Infinity) < t) {
      delete cache[key];
    }
  });
};

const hitCache = (url, maxAge) => {
  const now = Date.now();
  collectGarbage(now);
  const entry = cache[url];
  return entry && entry.time > now - maxAge && entry.promise
    ? entry.promise
    : null;
};

const fetchJson = (url, options = {}) => {
  const { maxAge = 0, method } = options;

  options = Object.assign({}, DEFAULT_OPTIONS, options);

  // cache is only filled
  const cachedValue = hitCache(url, maxAge);
  if (cachedValue) {
    return cachedValue;
  }

  const promise = fetchWithCsrf(getUrl(url), options).then((res) => {
    if (res.status === 204) {
      return null;
    }
    return res
      .json()
      .then((json) => {
        if (res.ok) {
          return json;
        } else {
          return Promise.reject(
            new MaltResponseError(
              `Server responded with status ${res.status} ${res.statusText}`,
              res,
              json
            )
          );
        }
      })
      .catch((e) => {
        let error;
        if (e instanceof MaltResponseError) {
          error = e;
        } else {
          error = new MaltResponseError(e, res);
        }
        return Promise.reject(error);
      });
  });

  // only GET requests are cached
  if (!method || method === 'GET') {
    addToCache(url, promise, maxAge);
  }
  return promise;
};

// initial fill
fillCache(global.API_CACHE);

// internal use for unit tests
export { addToCache, fillCache, removeFromCache, resetCache, cache };

export default fetchJson;
