import React from 'react';
import PropTypes from 'prop-types';
import { Mixin, strategies } from '@xingternal/hops-mixin';
import * as Sentry from '@sentry/browser';
import { captureException } from './browser';
import { configureFetchWithSentry } from './configure-fetch-with-sentry';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidCatch(error, errorInfo) {
    captureException(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

class HopsSentryBrowserMixin extends Mixin {
  bootstrap() {
    const { errorIgnoreList = [], ...options } = this.config.sentry || {};

    Sentry.init({
      ...options,
      beforeSend: this.sentryBeforeSend,
    });

    Sentry.setContext('Server Data', this.getServerData());

    this.canIgnoreError = ({ type, value } = {}) => {
      return (
        type === 'Error' &&
        typeof value === 'string' &&
        !!errorIgnoreList.find((filter) => !!value.match(filter))
      );
    };
  }

  enhanceElement(element) {
    return React.createElement(ErrorBoundary, {}, element);
  }

  configureFetch(fetch) {
    return configureFetchWithSentry(fetch, Sentry, this.config);
  }

  sentryBeforeSend(event) {
    if (/\bElectron\b/.test(navigator.userAgent)) {
      return null;
    }

    const { exception: { values: [error] = [] } = {} } = event || {};

    if (error && this.canIgnoreError(error)) {
      console.warn('ignoring known error:', error);

      return null;
    }

    return event;
  }
}

HopsSentryBrowserMixin.strategies = {
  sentryBeforeSend: strategies.sync.pipe,
};

export default HopsSentryBrowserMixin;
export { ErrorBoundary };
