import isNode from 'detect-node';
import setupTracking from './src/setupTracking';
import createMock from './src/createMock';
import { version as tttVersion, config } from './package.json';
import define from './constants';

const { apiVersion } = config || {};

define({
  API_VERSION: apiVersion,
  TTT_VERSION: tttVersion,
});

const ttt = isNode ? createMock() : setupTracking(global.window);

export default ttt;
