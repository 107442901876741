import uuid from '../uuid';

const _key = 'stm-v1';

const buildMessage = message => {
  const id = uuid();

  return {
    _key,
    id,
    ...message,
  };
};

const validateMessage = (message, action) => {
  return (
    typeof message === 'object' &&
    message._key === _key &&
    message.action === action
  );
};

const postMessage = (target, action, payload, overrides = {}) => {
  return new Promise(resolve => {
    target.postMessage(
      JSON.stringify(buildMessage({ action, payload, ...overrides })),
      '*'
    );
    setTimeout(resolve, 0);
  });
};

export { buildMessage, validateMessage, postMessage, _key };
