const console = global.console;
const apply = Function.prototype.apply;

const polyfill = {};

let nesting = 0;

[
  'log',
  'debug',
  'info',
  'warn',
  'error',
  'group',
  'groupCollapsed',
  'groupEnd',
].forEach(name => {
  polyfill[name] = (...args) => {
    if (!console) {
      return;
    }

    if (!console.group) {
      // emulate group logging
      if (name === 'group') {
        nesting += 1;
      } else if (name === 'groupEnd') {
        nesting -= 1;

        // skip actual logging
        return;
      } else if (nesting > 0) {
        for (let i = 0; i < nesting; i++) {
          args.unshift('  ');
        }
      }
    }

    const method = console[name] || console.log;

    // NOTE: For IE we need to do this in order to be able to apply our arguments!
    method && apply.call(method, console, args);
  };
});

export default console && console.group ? console : polyfill;
export { polyfill };
