import location from './location';
import sendDataScienceTrackingMutation from './sendDataScienceTrackingMutation';

const REGEXP_AMP = /&|\\u0026|\\x26|\\046|\\u\{26\}/g; // replaces "&"
const REGEXP_LT = /<|\\u003c|\\x3C|\\074|\\u\{3c\}/g; // replaces "<"
const REGEXP_GT = />|\\u003e|\\x3E|\\076|\\u\{3e\}/g; // replaces ">"
const REGEXP_RES_SERVER_ERROR = /^5/;
const REGEXP_RES_OK = /^2/;

/**
 * partly copied from recommendations_tracking.js (frontend/common implementation)
 */
function applySrcRewrites(href) {
  const srcRewrites = [
    {
      src: /\?.*/,
      replace: '',
    },
    {
      src: /(%2f)?cdr?kpi[/|_]?([a-z%\d.]+)?/,
      replace: '',
    },
    {
      src: /\/N(\d+\.)+[\da-z]+/,
      replace: '',
    },
    {
      src: /\/$/,
      replace: '',
    },
  ];

  return srcRewrites.reduce(
    (memo, rewrite) => memo.replace(rewrite.src, rewrite.replace),
    href
  );
}

/**
 * see xing.escape method realization
 */
function escapeString(string) {
  return `${string}`
    .replace(REGEXP_AMP, '&amp;')
    .replace(REGEXP_LT, '&lt;')
    .replace(REGEXP_GT, '&gt;');
}

/**
 * Splits the allPageEntries list into two
 * One with entries that match with the href,
 * another with those that don't
 */
function sliceMatchingEntries(allPagesEntries, href) {
  return allPagesEntries.reduce(
    ([currentPageEntries, otherPagesEntries], entry) =>
      href.indexOf(applySrcRewrites(entry.src)) !== -1
        ? [currentPageEntries.concat(entry), otherPagesEntries]
        : [currentPageEntries, otherPagesEntries.concat(entry)],
    [[], []]
  );
}

/**
 * The src value of an entry is set by the outgoing page.
 * This page can not know if there are redirects along the way.
 * This caused mismatches of the src/location.href values.
 * To fix this we resolve up to 5 unresolved src values on each page load.
 */
async function resolveEntries(allPagesEntries, fetch) {
  const unresolvedUrls = allPagesEntries
    .filter(({ resolved }) => !resolved)
    .map(({ src }) => src)
    .reverse(); /* newer entries are at the end of the array but more important */
  const uniqueUnresolvedUrls = [...new Set(unresolvedUrls)];
  const resolvedUrls = await Promise.all(
    /* only resolve 5 latest urls to prevent dos from huge lists */
    uniqueUnresolvedUrls.slice(0, 5).map(async (unresolvedSrc) => {
      try {
        const res = await fetch(unresolvedSrc, {
          method: 'HEAD',
          redirect: 'follow',
        });
        if (String(res.status).match(REGEXP_RES_SERVER_ERROR)) {
          /* When we encounter a server error, we'll retry to resolve next time */
          return null;
        }
        if (!String(res.status).match(REGEXP_RES_OK)) {
          throw new Error(
            `Unexpected response status ${res.status} when fetching ${unresolvedSrc}`
          );
        }

        return [
          unresolvedSrc,
          { src: res.url, resolved: new Date().getTime() },
        ];
      } catch (err) {
        /* Anything else failed, just assume the url can not be resolved */
        return [
          unresolvedSrc,
          { src: unresolvedSrc, resolved: new Date().getTime() },
        ];
      }
    })
  );
  const resolvedEntriesByOldSrc = Object.fromEntries(
    resolvedUrls.filter(Boolean)
  );

  return allPagesEntries.map((entry) =>
    resolvedEntriesByOldSrc[entry.src]
      ? {
          ...entry,
          ...resolvedEntriesByOldSrc[entry.src],
        }
      : entry
  );
}

/**
 * ensure keys are camelCased and omit unknown properties
 */
function normalizeTrackingDataKeys({
  consumer,
  service,
  trackingToken,
  interactionType,
  userId,
  position,
  receivedTimestamp,
  timestamp,
  tracking_token,
  interaction_type,
  user_id,
  received_timestamp,
}) {
  return {
    consumer,
    service,
    trackingToken: trackingToken || tracking_token,
    interactionType: interactionType || interaction_type,
    userId: userId || user_id,
    position,
    receivedTimestamp: receivedTimestamp || received_timestamp,
    timestamp,
  };
}

/**
 * Other pages might set data science tracking info into
 * the local storage. When the user then arrives on the target page
 * it should be send using this function
 */
export default async function sendDsTracking(userId, fetch) {
  if (typeof global === 'undefined') {
    /* do not run tracking on the server */
    return;
  }
  const data = global.localStorage.getItem('recommendation-tracking');
  if (data === null) {
    return;
  }

  const [matchingPageEntries, otherPagesEntries] = sliceMatchingEntries(
    await resolveEntries(JSON.parse(data), fetch),
    applySrcRewrites(escapeString(location.href))
  );
  const latestMatchingPageEntry = matchingPageEntries.pop();
  /* rest of the matching entries is discarded */

  if (latestMatchingPageEntry) {
    await sendDataScienceTrackingMutation(fetch, [
      normalizeTrackingDataKeys({
        userId,
        ...JSON.parse(latestMatchingPageEntry.data),
      }),
    ]);
  }

  /* keep left tracking data in local storage and update resolved entries */
  otherPagesEntries.length
    ? global.localStorage.setItem(
        'recommendation-tracking',
        JSON.stringify(otherPagesEntries)
      )
    : global.localStorage.removeItem('recommendation-tracking');
}
