import 'cross-fetch/polyfill';

import { createElement, Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { Mixin } from '@xingternal/hops-mixin';
import { Helmet } from 'react-helmet-async';

import { shouldPolyfill as localeShouldPolyfill } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as pluralShouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as relativetimeformatShouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as getcanonicallocalesShouldPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as numberFormatShouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill';

class I18nMixin extends Mixin {
  constructor(config, element, { i18n: options = {} } = {}) {
    super(config);

    this.options = options;
  }

  bootstrap() {
    const serverData = this.getServerData();
    this.language = serverData.language;

    if (!this.language) {
      throw new Error('No language set.');
    }

    return import(
      /* webpackChunkName: "hops-language-[request]" */ `locales/${this.language}`
    ).then(async (locale) => {
      const { messages, localeData } = locale;
      this.messages = messages;
      this.localeData = localeData;

      if (getcanonicallocalesShouldPolyfill()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
      }

      if (localeShouldPolyfill()) {
        await import('@formatjs/intl-locale/polyfill');
      }

      // This locale is supported
      if (pluralShouldPolyfill(this.language)) {
        // Load the polyfill 1st BEFORE loading data
        await import('@formatjs/intl-pluralrules/polyfill-force');
        await import(`@formatjs/intl-pluralrules/locale-data/${this.language}`);
      }

      // This locale is supported
      if (numberFormatShouldPolyfill(this.language)) {
        // Load the polyfill 1st BEFORE loading data
        await import('@formatjs/intl-numberformat/polyfill-force');
        await import(
          `@formatjs/intl-numberformat/locale-data/${this.language}`
        );
      }

      if (relativetimeformatShouldPolyfill(this.language)) {
        // Load the polyfill 1st BEFORE loading data
        // Polyfill needed for IE11 to make Intl.RelativeTimeFormat work
        // see https://formatjs.io/docs/polyfills/intl-relativetimeformat/#requirements
        await import('@formatjs/intl-relativetimeformat/polyfill-force');
        await import(
          `@formatjs/intl-relativetimeformat/locale-data/${this.language}`
        );
      }
    });
  }

  configureFetch(fetch) {
    return (...fetchArgs) => {
      const request = new Request(...fetchArgs);
      request.headers.set('Accept-Language', this.language);

      return fetch(request);
    };
  }

  enhanceElement(reactElement) {
    const providerOptions = this.options.intlProvider || {};

    return createElement(
      Fragment,
      {},
      createElement(Helmet, {}, createElement('html', { lang: this.language })),
      createElement(
        IntlProvider,
        {
          locale: this.language,
          messages: this.messages,
          textComponent: Fragment,
          ...providerOptions,
        },
        reactElement
      )
    );
  }
}

export default I18nMixin;
