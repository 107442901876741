import isNative from './isNative';
import assignPolyfill from './Object/assign';
import promisePolyfill from './Promise';

const assignNative = global.Object.assign;
const promiseNative = global.Promise;

const assign = isNative(assignNative) ? assignNative : assignPolyfill;
const Promise = isNative(promiseNative) ? promiseNative : promisePolyfill;

export { assign, Promise };
