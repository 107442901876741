import { buildMessage } from './helpers';
import tryParseMessage from './tryParseMessage';

const subscriber = (window, origin = '*') => {
  return (action, callback, options = {}) => {
    const handler = e => {
      // ignore wrong origins
      if (origin !== '*' && e.origin !== origin) {
        return false;
      }

      const message = tryParseMessage(e.data);

      if (!message || message.action !== action) {
        return false;
      }

      const { fullResponse } = options;
      const { id } = message;

      const promise = new Promise((resolve, reject) => {
        try {
          const result = callback(fullResponse ? message : message.payload);

          resolve(result);
        } catch (error) {
          reject(error);
        }
      });

      if (id && e.source && action.indexOf('response:') !== 0) {
        promise
          .then(result => [null, result])
          .catch(error => [(error && error.message) || 'error', null])
          .then(([error, payload]) => {
            const response = buildMessage({
              id,
              action: `response:${action}:${id}`,
              error,
              payload,
            });

            e.source.postMessage(JSON.stringify(response), '*');
          });
      }

      return true;
    };

    window.addEventListener('message', handler, false);

    return () => {
      window.removeEventListener('message', handler, false);
    };
  };
};

export default subscriber;
