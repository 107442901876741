export default class TttTracking {
  constructor(ttt) {
    this.ttt = ttt;
  }

  trackViewBanner() {
    this._trackGlobalAction('cookie_consent_banner_view');
  }

  trackShowSettings() {
    this._trackGlobalAction('cookie_consent_banner_second_layer_view');
  }

  trackAcceptAll() {
    this._trackGlobalAction('cookie_consent_banner_accept_all');
  }

  trackAcceptMarketing(choices) {
    const flag = choices.marketing ? '1' : '0';
    this._trackGlobalAction('cookie_consent_banner_accept_selection', {
      PropActionOrigin: `cookie_consent_banner_second_layer_marketing-${flag}`,
    });
  }

  trackHideSettings() {
    this._trackGlobalAction('cookie_consent_banner_second_layer_close');
  }

  trackViewLegacyBanner() {
    this._trackGlobalAction('cookie_consent_banner_old_view');
  }

  _trackGlobalAction(actionName, opts = {}) {
    try {
      if (this.ttt) {
        if (this.ttt._initCalled) {
          this._sendTttEvent(actionName, opts);
        } else {
          setTimeout(() => {
            this._sendTttEvent(actionName, opts);
          }, 1000);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        `Failed to publish ttt event: PropGlobalAction ${actionName}`
      );
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  _sendTttEvent(actionName, opts) {
    this.ttt.event('PropGlobalAction', {
      PropGlobalAction: actionName,
      PropPostponeAction: 1,
      ...opts,
    });
  }
}
