import { defineMessages } from 'react-intl';

export default defineMessages({
  headline: {
    id: 'WELCOME_LOGIN_HEADLINE',
    defaultMessage: 'Willkommen Zurück!',
  },
  usernameLabel: {
    id: 'WELCOME_LOGIN_USERNAME_LABEL',
    defaultMessage: 'E-Mail / Handynummer',
  },
  passwordLabel: {
    id: 'WELCOME_LOGIN_PASSWORD_LABEL',
    defaultMessage: 'Passwort',
  },
  problemsLoggingIn: {
    id: 'WELCOME_LOGIN_PROBLEMS_LOGGING_IN',
    defaultMessage: 'Probleme beim Einloggen?',
  },
  continueWithGoogle: {
    id: 'WELCOME_STARTPAGE_PROCEED_WITH_GOOGLE_BUTTON',
    defaultMessage: 'Weiter mit Google',
  },
  continueWithApple: {
    id: 'WELCOME_STARTPAGE_PROCEED_WITH_APPLE_BUTTON',
    defaultMessage: 'Weiter mit Apple',
  },
  submitLabel: {
    id: 'WELCOME_LOGIN_SUBMIT_LABEL',
    defaultMessage: 'Einloggen',
  },
  submitRequestError: {
    id: 'WELCOME_LOGIN_FORM_SUBMIT_REQUEST_ERROR',
    defaultMessage:
      'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte noch einmal.',
  },
  emailInvalidError: {
    id: 'WELCOME_APP_MINIREG_ERROR_EMAIL',
    defaultMessage: 'Überprüfe bitte Deine E-Mail-Adresse',
  },
  usernameRequiredError: {
    id: 'WELCOME_LOGIN_FORM_USERNAME_REQUIRED_ERROR',
    defaultMessage: 'Geben Sie ihre E-Mail Adresse oder Handynummer ein.',
  },
  passwordRequiredError: {
    id: 'WELCOME_LOGIN_FORM_PASSWORD_REQURED_ERROR',
    defaultMessage: 'Geben Sie ihr Passwort ein.',
  },
  rememberMe: {
    id: 'WELCOME_LOGIN_FORM_REMEMEMBER_ME_LABEL',
    defaultMessage: 'Eingeloggt bleiben',
  },
  registerNow: {
    id: 'LOGIN_NEW_REGISTER_TEXT',
    defaultMessage: 'Noch kein XING-Mitglied? Jetzt kostenlos registrieren.',
  },
  hidePasswordText: {
    id: 'ACCOUNT_FORM_HIDE_PASSWORD_TEXT',
    defaultMessage: 'Passwort verstecken',
  },
  showPasswordText: {
    id: 'ACCOUNT_FORM_SHOW_PASSWORD_TEXT',
    defaultMessage: 'Passwort anzeigen',
  },
  partnerAuthIntroduction: {
    id: 'LOGIN_OAUTH_INFO_PARAGRAPH',
    defaultMessage:
      'Bitte loggen Sie sich mit Ihren XING Zugangsdaten ein und gewähren Sie der Anwendung {application} Zugriff auf Ihre bei XING gespeicherten Daten.',
  },
  loginInvalidPassword: {
    id: 'LOGIN_INVALID_PASSWORD',
    defaultMessage:
      'Diese Zugangsdaten sind leider nicht korrekt ... vielleicht vertippt?',
  },
  tandcLabel: {
    id: 'WELCOME_REGISTRATION_TANDC_LABEL',
    defaultMessage: 'AGB',
  },
  dataprotectionLabel: {
    id: 'WELCOME_REGISTRATION_DATAPROTECTION_LABEL',
    defaultMessage: 'Datenschutzbedingungen',
  },
  tandcDescription: {
    id: 'WELCOME_REGISTRATION_TANDC_DESCRIPTION',
    defaultMessage:
      'Mit der Registrierung akzeptiere ich die {tandc} und habe die {dataprotection} zur Kenntnis genommen.',
  },
  dataprotectionLink: {
    id: 'WELCOME_REGISTRATION_DATAPROTECTION_LINK',
    defaultMessage: 'https://privacy.xing.com/de/datenschutzerklaerung',
  },
});
