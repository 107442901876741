import getConfig from './config';
import sendBeacon, { isBeaconApiSupported } from '../helper/sendBeacon';
import postTracking from '../helper/postTracking';
import getUnixTimestamp from '../helper/getUnixTimestamp';
import {
  Promise,
  assign,
  Future,
  getCookie,
  setCookie,
  getSecondLevelDomain,
  uuid,
  getClientsideVisitorId,
  getTrackingEndpointUrl,
} from '../../../lib/';

import {
  CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST,
  ABACUS_TRACKING_PATH,
} from '../../../constants';
import { version } from '../../../package.json';

const getBrowserWidth = () => {
  const document = window.document;

  if (window.innerWidth) {
    return window.innerWidth;
  } else if (document && document.documentElement) {
    return document.documentElement.offsetWidth;
  }
  return '';
};

const getBrowserHeight = () => {
  const document = window.document;

  if (window.innerHeight) {
    return window.innerHeight;
  } else if (document && document.documentElement) {
    return document.documentElement.offsetHeight;
  }
  return '';
};

const getScreenResolution = () => {
  if (window.screen) {
    return `${window.screen.width}x${window.screen.height}`;
  }
  return '';
};

const getFilteredProps = (
  props,
  filterKeys = ['PropDestinationUrl', 'PropReferringUrl', 'pageName']
) => {
  const properties = {};

  Object.keys(props)
    .filter(key => {
      return filterKeys.indexOf(key) === -1;
    })
    .forEach(key => {
      if (Array.isArray(props[key])) {
        assign(properties, { [key]: props[key].join(',') });
      } else {
        assign(properties, { [key]: props[key] });
      }
    });

  return properties;
};

const tracker = {};

const pageviewFuture = new Future();
export const xingDataFuture = new Future();

let firstEvent = true;

export const resetFirstEvent = () => {
  firstEvent = true;
};

const sendEvent = (props, event, url) => {
  const PropAppId = `ttt@${version}${
    props.PropAppId ? ',' + props.PropAppId : ''
  }`;
  return new Promise(resolve => {
    const payload = {
      clientTimestamp: getUnixTimestamp(),
      pageName: props.pageName,
      location: props.PropDestinationUrl,
      referrer: props.PropReferringUrl || undefined,
      properties: getFilteredProps({ ...props, PropAppId }),
      browserWidth: getBrowserWidth(),
      browserHeight: getBrowserHeight(),
      resolution: getScreenResolution(),
      type: 'web',
      eventName: event,
    };
    if (isBeaconApiSupported()) {
      resolve(sendBeacon(url, payload));
    } else {
      resolve(postTracking(url, payload));
    }
  });
};

const setClientsideVisitorIdCookie = domain => {
  const cookieName = 'c_visitor_id';
  if (!getCookie(cookieName)) {
    setCookie(cookieName, uuid(), 1000 * 5, { domain }); // Cookie expires after 5 seconds
  }
};

const postponeActionEvent = (props, event, endpointUrl) => {
  pageviewFuture.then(pageviewProps => {
    const eventProps = { ...props };

    ['pageName', 'PropChannel', 'PropApplication'].forEach(element => {
      if (!eventProps[element] || eventProps[element] === 'undefined') {
        eventProps[element] = pageviewProps[element];
      }
    });
    sendEvent(eventProps, event, endpointUrl);
  });
};

tracker.track = (env, event, props) => {
  const endpointUrl = getTrackingEndpointUrl(
    getConfig(env),
    env,
    ABACUS_TRACKING_PATH
  );
  if (firstEvent) {
    setClientsideVisitorIdCookie(getSecondLevelDomain());
    firstEvent = false;
  }

  if (
    CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST.includes(getSecondLevelDomain())
  ) {
    props = assign({}, props, { csodVisitorId: getClientsideVisitorId() });
  }

  if (event === 'pageview' || !props.PropPostponeAction) {
    pageviewFuture.resolve(props);
    sendEvent(props, event, endpointUrl);
  } else {
    postponeActionEvent(props, event, endpointUrl);
  }
};

export default tracker;
