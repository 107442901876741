import CommonI18nMixin from './mixin.runtime-common';

class I18nBrowserMixin extends CommonI18nMixin {
  bootstrap() {
    if (global.Intl) {
      return super.bootstrap();
    }

    // https://github.com/andyearnshaw/Intl.js#intljs-and-browserifywebpack
    return import('intl').then(() =>
      Promise.all([
        super.bootstrap(),
        import(`intl/locale-data/jsonp/${this.language}.js`),
      ])
    );
  }
}

export default I18nBrowserMixin;
