import styled from 'styled-components';

import { StatusBanner } from '@xingternal/banner';
import { Button } from '@xingternal/button';
import { spaceL, spaceXL, scale080 } from '@xingternal/tokens';
import { mediaConfined } from '@xingternal/layout-tokens';

const FakeInput = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const FormMessageBanner = styled(StatusBanner)`
  margin-bottom: ${spaceXL};
`;

const HideSmallerThanTablet = styled.div`
  display: none;

  @media ${mediaConfined} {
    display: inline-flex;
  }
`;

const HiddenWrapper = styled.div`
  display: none;
`;

const InputActions = styled.div`
  margin-top: ${spaceXL};
  margin-bottom: ${spaceXL};
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const TextCenter = styled.div`
  margin-top: ${spaceL};
  text-align: center;
`;

const InputWrapper = styled.div`
  margin-bottom: ${spaceXL};
`;

const Label = styled.label`
  font-size: ${scale080};
  vertical-align: super;
  padding-left: 8px;
  cursor: pointer;
`;

const GoogleButton = styled(Button)`
  width: 100%;
  margin-top: ${spaceL};
`;

const AppleButton = styled(Button)`
  width: 100%;
  margin-top: ${spaceL};
`

export {
  FakeInput,
  FormMessageBanner,
  HideSmallerThanTablet,
  HiddenWrapper,
  InputActions,
  InputWrapper,
  Label,
  SubmitButton,
  AppleButton,
  GoogleButton,
  TextCenter,
};
