import React from 'react';
import PropTypes from 'prop-types';

import Screen from './Screen';

import { noJavascriptMessages as messages } from './messages';

const NoJavascript = ({ children }) => (
  <React.Fragment>
    <noscript>
      <style>{`#javascript-content { display: none; }`}</style>
      <Screen messages={messages} />
    </noscript>
    <div id={'javascript-content'}>{children}</div>
  </React.Fragment>
);

NoJavascript.propTypes = {
  /* children */
  children: PropTypes.node.isRequired,
};

export default NoJavascript;
