import { Future, publisher, subscriber } from '../lib/';

import createFrame from './createFrame';

const createSandbox = window => {
  let withFrame = new Future();
  const withHandler = new Future();

  const sandbox = {};

  sandbox.load = url => {
    return createFrame(window, url).then(iframe => {
      withFrame.resolve(iframe);

      const match = url.match(/(https?:\/\/[^/&?]+)/, '');
      const origin = match ? match[1] : '*';

      const publish = publisher(window, iframe.contentWindow, origin);
      const subscribe = subscriber(window, '*');

      const handler = {
        iframe,
        publish,
        subscribe,
      };

      withHandler.resolve(handler);

      return iframe;
    });
  };

  sandbox.publish = (...args) => {
    return withHandler.then(handler => {
      return handler.publish(...args);
    });
  };

  sandbox.subscribe = (...args) => {
    return withHandler.then(handler => {
      return handler.subscribe(...args);
    });
  };

  sandbox.unload = (event, props) => {
    return (
      withFrame &&
      withFrame.then(iframe => {
        window.document.body.removeChild(iframe);

        withFrame = null;
      })
    );
  };

  return sandbox;
};

export default createSandbox;
