import { useMutation } from '@apollo/client';

import CREATE_CV_UPLOAD_PARSED_CV_MUTATION from './createCvUploadParsedCvMutation.graphql';

const useGetParsedCvData = () => {
  const [createParsedCv] = useMutation(CREATE_CV_UPLOAD_PARSED_CV_MUTATION);

  const getParsedCvData = async (uploadId, source, flowIdentifier) => {
    if (!uploadId || !source || !flowIdentifier) {
      // we do not have all the required fields
      return;
    }

    const { data } = await createParsedCv({
      variables: { uploadId, source, flowIdentifier },
    });

    if (!data?.createCvUploadParsedCv?.id) {
      // we did not receive a parsedCvID -> something went wrong
      return;
    }

    let parsedData = {
      id: data.createCvUploadParsedCv.id,
    };

    // extract personal data if available
    if (data?.createCvUploadParsedCv?.textkernelPayload?.personal) {
      const {
        emails,
        firstName = '',
        lastName = '',
      } = data.createCvUploadParsedCv.textkernelPayload.personal;

      let email;
      if (emails && emails[0]) {
        if (emails[0].email) {
          email = emails[0].email;
        } else if (typeof emails[0] === 'string') {
          email = emails[0];
        }
      }

      parsedData = {
        ...parsedData,
        firstName,
        lastName,
        email,
      };
    }

    return parsedData;
  };

  return { getParsedCvData };
};

export default useGetParsedCvData;
