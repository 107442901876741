import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { GridContainer } from '@xingternal/xing-grid';

import RegistrationForm from '@xingternal/account-packages/registration';
import LoginForm from '@xingternal/account-packages/login';

const registrationProps = {
  externalUrl: 'http://localhost:8080',
  googleSiteKey: '12345',
  signupChannel: 'brewery_io',
  language: 'en',
  emailOnly: false,
};

const loginProps = {
  externalUrl: 'http://localhost:8080',
  externalLoginUrl: 'http://localhost:8080',
  loggedOutSid: '12345',
};

const navItemStyles = { display: 'inline-block', marginRight: '8px' };

export default () => {
  return (
    <React.Fragment>
      <nav>
        <ul>
          <li style={navItemStyles}>
            <Link to="/_demo/registration">Registration</Link>
          </li>
          <li style={navItemStyles}>
            <Link to="/_demo/login">Login</Link>
          </li>
        </ul>
      </nav>

      <GridContainer type="wide">
        <div
          style={{
            padding: '0 16px',
            maxWidth: '500px',
            width: '100%',
            margin: '0 auto',
          }}
        >
          <Switch>
            <Route path="/_demo/login">
              <LoginForm {...loginProps} />
            </Route>
            <Route path="/_demo/registration">
              <RegistrationForm {...registrationProps} />
            </Route>
            <Route path="/" exact>
              Overview of account-packges
            </Route>
          </Switch>
        </div>
      </GridContainer>
    </React.Fragment>
  );
};
