import { Promise } from '../../../lib/';

const BEACON_API_SUPPORTED =
  global.navigator && 'sendBeacon' in global.navigator;

const isBeaconApiSupported = () => {
  return BEACON_API_SUPPORTED;
};

/**
 * Post tracking payload asynchronous and non-blocking as JSON using the Beacon API
 * see https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API/Using_the_Beacon_API
 *
 * We cannot set the correct Content-Type header because of a bug in Chrome
 * see http://crbug.com/490015
 *
 * @param {string} url
 * @param {Object} payload
 */
const sendBeacon = (url, payload) => {
  return new Promise((resolve, reject) => {
    navigator.sendBeacon(url, JSON.stringify(payload))
      ? resolve()
      : reject(new Error(`Wasn't able to queue data in Beacon API`));
  });
};

export default sendBeacon;
export { isBeaconApiSupported };
