import {
  getUserConsentCookieValue,
  setUserConsentCookie,
  deleteUserConsentCookie,
} from './cookie';
import { hostResolver } from './host-resolver';

export class BackendDataSource {
  constructor({ backendApi = null, fetch = window.fetch }) {
    this.backendApi = backendApi || hostResolver();
    this.fetch = fetch.bind(window);
  }

  getUserConsent() {
    return Promise.resolve(getUserConsentCookieValue() || {});
  }

  setUserConsent(consent) {
    return this.fetch(this._userConsentPath, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(consent),
    })
      .then((response) => response.json())
      .catch((error) => {
        // Fallback to JS cookie in case of errors
        window.console.warn(
          '[CookieConsent] ******* falling back to JS ******'
        );
        window.console.warn('[CookieConsent] This should not happen ******');
        window.console.error(error);
        setUserConsentCookie(JSON.stringify(consent));
      });
  }

  resetUserConsent() {
    deleteUserConsentCookie();
    return this.fetch(this._userConsentPath, {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    });
  }

  get _userConsentPath() {
    return `${this.backendApi}/user_consent`;
  }
}
