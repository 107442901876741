import styled from 'styled-components';

import {
  xdlColorControlFillTertiary,
  xdlColorError,
  xdlColorCaution,
  xdlColorSuccess,
  xdlColorHighlighted,
  xdlColorTextSecondaryOnLight,
} from '@xingternal/tokens';

const colors = {
  0: xdlColorControlFillTertiary,
  1: xdlColorError,
  2: xdlColorCaution,
  3: xdlColorSuccess,
  4: xdlColorHighlighted,
};

const strengthToColor = strength => {
  const range = Object.keys(colors);
  const min = Math.min(...range);
  const max = Math.max(...range);

  if (strength < min) return colors[min];
  if (strength > max) return colors[max];

  return colors[strength];
};

const strengthToWidth = strength => 20 * strength + '%';

const PasswordStrengthIndicator = styled.div`
  margin-top: 8px;
  height: 6px;
  position: relative;
  overflow: hidden;
  background-color: ${colors[0]};

  &:before {
    bottom: 0px;
    content: '';
    left: 0px;
    position: absolute;
    top: 0px;
    transition: all 0.5s ease-out 0s;
    background: ${({ strength }) => strengthToColor(strength)};
    width: ${({ strength }) => strengthToWidth(strength)};
  }
`;

const PasswordStrengthIndicatorText = styled.div`
  text-align: center;
  margin-top: 6px;
  font-size: 12px;
  font-style: italic;
  font-weight: light;
  color: ${({ error }) =>
    error ? xdlColorError : xdlColorTextSecondaryOnLight};
`;

export { PasswordStrengthIndicatorText, PasswordStrengthIndicator };
