import { Promise } from '../lib/';

const createFrame = (window, url) => {
  return new Promise(resolve => {
    const document = window.document;

    const width = window.innerWidth || 0;
    const height = window.innerHeight || 0;

    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'stm');
    iframe.setAttribute(
      'sandbox',
      'allow-scripts allow-same-origin allow-forms'
    );
    iframe.setAttribute(
      'style',
      `top:-${height}px;left:-${width}px;width:${width}px;height:${height}px;border:0;position:absolute;visibility:hidden;opacity:0`
    );

    iframe.onload = () => resolve(iframe);
    // iframe.onerror = (error) => reject(error);

    iframe.setAttribute('src', url);

    const attachFrame = () => {
      const body = document.body;

      body.insertBefore(iframe, body.childNodes[0]);
    };

    if (document.body) {
      attachFrame();
    } else {
      document.addEventListener('DOMContentLoaded', attachFrame, true);
    }
  });
};

export default createFrame;
