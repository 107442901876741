/* eslint-disable import/no-unresolved */
/* eslint-disable node/no-missing-import */
import entryPoint from '@xingternal/hops/entrypoint';
/* eslint-enable node/no-missing-import */
/* eslint-enable import/no-unresolved */

entryPoint();

if (module.hot) {
  module.hot.accept('@xingternal/hops/entrypoint', () => entryPoint());
}
