const setCookie = (name, value, ttl = null, options = { path: '/' }) => {
  if (typeof ttl === 'number') {
    const date = new Date();
    date.setTime(date.getTime() + ttl);

    options.expires = date.toUTCString();
  }

  options = { SameSite: 'Lax; Secure', ...options };

  const optionsString = Object.keys(options)
    .map(key => `${key}=${options[key]}`)
    .join(';');

  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )};${optionsString}`;
};

const getCookie = name => {
  const pattern = `${encodeURIComponent(name)}=`;
  const cookies = document.cookie.split(';').map(c => c.trim());

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];

    if (cookie.indexOf(pattern) === 0) {
      return decodeURIComponent(
        cookie.substring(pattern.length, cookie.length)
      );
    }
  }

  return null;
};

export { setCookie, getCookie };
