import { Mixin } from '@xingternal/hops-mixin';

import { reload } from './reload';

const AUTH_STATE_HEADER = 'XING-ONE-AUTHENTICATION-STATE';

const kebabify = (value) => {
  return String(value || '')
    .toLowerCase()
    .replace(/_/g, '-');
};

class HopsUserAuthBrowserMixin extends Mixin {
  configureFetch(fetch) {
    const hopsAuthState = kebabify(global._hopsServerData.loginState);

    return (...args) => {
      return fetch(...args).then((response) => {
        const xingOneAuthState = kebabify(
          response.headers.get(AUTH_STATE_HEADER)
        );

        if (
          hopsAuthState &&
          xingOneAuthState &&
          hopsAuthState !== xingOneAuthState
        ) {
          reload();
        }

        return response;
      });
    };
  }
}

export default HopsUserAuthBrowserMixin;
