import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { Headline, BodyCopy } from '@xingternal/typography';

import Recaptcha from './Recaptcha';
import messages from './messages';

const RecaptchaForm = ({
  googleSiteKey,
  setFieldValue,
  language,
  onSubmit,
}) => {
  return (
    <React.Fragment>
      <Headline size="xxlarge">
        <FormattedMessage {...messages.recaptchaHeadline} />
      </Headline>

      <BodyCopy size="large">
        <FormattedMessage {...messages.recaptchaParagraph} />
      </BodyCopy>

      <Recaptcha
        setFieldValue={setFieldValue}
        onSubmit={onSubmit}
        language={language}
        googleSiteKey={googleSiteKey}
      />
    </React.Fragment>
  );
};

RecaptchaForm.propTypes = {
  googleSiteKey: PropTypes.string,
  language: PropTypes.string,
  onSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default RecaptchaForm;
