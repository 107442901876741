export { Mixin, strategies } from '@xingternal/hops-mixin';
export { withLoginState, useLoginState } from './login-state';

export {
  render,
  Header,
  Miss,
  Status,
  ServerDataContext,
  withServerData,
  useServerData,
  importComponent,
  ConfigContext,
  withConfig,
  useConfig,
} from '@xingternal/hops-react';

export { default as config } from '@xingternal/hops-config';

export {
  withFetch,
  useFetch,
  withFetchJson,
  useFetchJson,
  HopsResponseError,
  FetchContext,
} from '@xingternal/hops-fetch';

export { Sentry, captureException } from '@xingternal/hops-sentry';

export {
  LOGIN_STATE,
  resolvers as userAuthResolvers,
} from '@xingternal/hops-user-auth';

export { mockData, mockDataResolvers } from '@xingternal/hops-xing-one';

export const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';
export const isServer = !isBrowser;
