import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Hero, BodyCopy } from '@xingternal/typography';
import cx from 'classnames';

import CheckboxSettings from './settings/CheckboxSettings';
import styles from './CookieSettings.css';
import sharedStyles from './shared.css';
import { UserChoicesTypes, ModalConfigurationTypes } from './types';
import { useCookieConsentContext } from './CookieConsentProvider';

const CookieSettings = ({
  userChoices,
  resources,
  acceptChoices,
  setUserChoices,
  handleAcceptAll,
}) => {
  const { setShowSettings } = useCookieConsentContext();

  useEffect(() => {
    setShowSettings(true);

    return () => {
      setShowSettings(false);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <Hero
          size="small"
          className={'consent-banner-overlay-title'}
          id={'consent-settings-title'}
        >
          {resources.title}
        </Hero>
        <BodyCopy
          className={cx(
            'consent-banner-overlay-description',
            sharedStyles.text,
            sharedStyles.bigText,
            styles.description
          )}
          id={'consent-settings-text'}
          dangerouslySetInnerHTML={{ __html: resources.description }}
        />
      </div>

      <CheckboxSettings
        acceptChoices={acceptChoices}
        resources={resources}
        handleAcceptAll={handleAcceptAll}
        userChoices={userChoices}
        setUserChoices={setUserChoices}
      />
    </div>
  );
};

CookieSettings.propTypes = {
  resources: ModalConfigurationTypes.isRequired,
  acceptChoices: PropTypes.func.isRequired,
  handleAcceptAll: PropTypes.func.isRequired,
  userChoices: UserChoicesTypes,
  setUserChoices: PropTypes.func,
};

export default CookieSettings;
