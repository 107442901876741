import React from 'react';
import { Mixin } from '@xingternal/hops-mixin';
import { Provider } from '../../context';

class HopsFetchReactContextBrowserMixin extends Mixin {
  enhanceElement(reactElement) {
    const { fetch, fetchJson } = this;

    return React.createElement(
      Provider,
      { value: { fetch, fetchJson } },
      reactElement
    );
  }
}

export default HopsFetchReactContextBrowserMixin;
