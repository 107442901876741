export const JSON_HEADERS = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json',
};

export const INITIAL_FORM_VALUES = {
  username: '',
  password: '',
  perm: true,
};
