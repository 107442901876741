import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Screen from './Screen';

import { noCookiesMessages as messages } from './messages';

const checkCookie = (name) => {
  if (!global.document) return false;

  global.document.cookie = name;
  const canReadCookies = global.document.cookie.indexOf(name) > -1;
  global.document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

  return canReadCookies;
};

const cookiesEnabled = () => {
  if (typeof navigator === 'undefined') {
    return checkCookie('cookies_enabled_check');
  }

  return navigator.cookieEnabled;
};

const NoCookies = ({ children }) => {
  const [cookieEnabled, setCookieEnabled] = useState(true);

  useEffect(() => {
    setCookieEnabled(cookiesEnabled());
  }, [cookieEnabled]);

  if (cookieEnabled) return children;

  return <Screen messages={messages} />;
};

NoCookies.propTypes = {
  /* children */
  children: PropTypes.node.isRequired,
};

export default NoCookies;
