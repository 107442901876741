import createLogger from './logging/createLogger';
import { DEBUG } from './logging/constants';

const logger = createLogger('xhr');

const fetchJSON = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    const { method = 'GET', params } = options;
    const withData = params !== undefined;

    logger.inspect(DEBUG, `${method} ${url} request`, params || {});

    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.withCredentials = true;

    if (withData) {
      xhr.setRequestHeader('Content-Type', 'application/json');
    }

    xhr.onerror = reject;
    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);

      logger.inspect(DEBUG, `${method} ${url} response`, data || {});

      resolve(data);
    };
    xhr.send(JSON.stringify(params));
  });
};

export default fetchJSON;
