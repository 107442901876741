const getHostnameAndPath = url => `${url.hostname}${url.pathname}`;

const determineActualUrl = (dlUrl, wdnUrl) => {
  const windowUrl = new URL(wdnUrl);
  if (!dlUrl) return windowUrl;

  const dataLayerUrl = new URL(dlUrl);

  if (getHostnameAndPath(dataLayerUrl) === getHostnameAndPath(windowUrl)) {
    return dataLayerUrl;
  }
  return windowUrl;
};

export default determineActualUrl;
