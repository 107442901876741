import React, { useEffect, useRef, useState } from 'react';

import { OmView } from '@xingternal/platform-layout-om';

import styles from './CookieConsentModal.css';
import CookieSettings from './CookieSettings';
import { useCookieConsentContext } from './CookieConsentProvider';

export const CookieSettingsContainer = ({
  resources,
  tttService,
  userChoices,
  acceptAll,
  acceptSelected,
}) => {
  const acceptInternalSelected =
    (hideOmView, acceptSelected, tttService) => (userChoices) => {
      tttService.trackAcceptMarketing(userChoices);
      hideOmView();
      acceptSelected(userChoices);
    };

  const acceptInternalAll =
    (acceptAll, tttService, hideOmView = () => {}) =>
    () => {
      tttService.trackAcceptAll();
      hideOmView();
      acceptAll();
    };

  const { setShowSettings, showSettings } = useCookieConsentContext();
  const openOmViewRef = useRef(() => null);

  useEffect(() => {
    if (showSettings) {
      openOmViewRef.current();
    }
  }, [showSettings]);

  const [newUserChoices, setNewUserChoices] = useState(userChoices);
  return (
    <OmView
      className={styles.settingsContainer}
      onClose={() => {
        tttService.trackHideSettings();
        setShowSettings(false);
      }}
      trigger={(activateOmView) => {
        openOmViewRef.current = activateOmView;
        return null;
      }}
    >
      {({ handleClose }) => (
        <CookieSettings
          resources={resources.modal}
          userChoices={newUserChoices}
          setUserChoices={setNewUserChoices}
          handleAcceptAll={acceptInternalAll(
            acceptAll,
            tttService,
            handleClose
          )}
          acceptChoices={acceptInternalSelected(
            handleClose,
            acceptSelected,
            tttService
          )}
        />
      )}
    </OmView>
  );
};
