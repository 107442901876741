export * from '@apollo/client';
export * from '@apollo/client/react/components';
export * from '@apollo/client/react/hoc';

import createDeprecate from 'depd';

const deprecate = createDeprecate('@xingternal/hops-xing-one');
deprecate(
  '[DEP0010] react-apollo is deprecated and has been aliased to @apollo/client. Please update your dependencies manually. (https://source.xing.com/fea/hops/blob/master/DEPRECATIONS.md#dep0010)'
);
