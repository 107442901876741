import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { TextButton } from '@xingternal/button';
import { Headline, BodyCopy } from '@xingternal/typography';

import GearImage from './GearImage';

import styles from './Screen.css';

const Screen = ({ messages }) => (
  <div className={styles.wrapper}>
    <div className={styles.illustration}>
      <GearImage />
    </div>

    <Headline size="xxlarge">
      <FormattedMessage {...messages.title} />
    </Headline>

    <BodyCopy size="large">
      <FormattedMessage {...messages.paragraph} />
    </BodyCopy>

    <FormattedMessage {...messages.linkTarget}>
      {(linkTarget) => (
        <TextButton size="medium" href={linkTarget}>
          <FormattedMessage {...messages.linkText} />
        </TextButton>
      )}
    </FormattedMessage>
  </div>
);

Screen.propTypes = {
  /* messages object */
  messages: PropTypes.shape({
    title: PropTypes.object,
    paragraph: PropTypes.object,
    linkTarget: PropTypes.object,
    linkText: PropTypes.object,
  }).isRequired,
};

export default Screen;
