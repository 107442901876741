import React from 'react';
import { captureException, Mixin } from '@xingternal/hops';
import { XingHubProvider } from '@xingternal/xing-hub';

export default class PrefetchRuntimeMixin extends Mixin {
  enhanceServerData(serverData, _req, res) {
    return {
      ...serverData,
      xingContext: res.locals.xingContext || {},
    };
  }

  enhanceElement(element) {
    const { xingContext } = this.getServerData();

    const {
      config: { platform = {} },
      fetch,
      fetchJson,
    } = this;
    const contextData = {
      ...xingContext,
      captureException,
      config: { ...platform, whitelistedCookies: undefined },
      fetch,
      fetchJson,
    };
    return <XingHubProvider value={contextData}>{element}</XingHubProvider>;
  }

  getTemplateData(data) {
    return {
      ...data,
      fragments: {
        ...data.fragments,
        script: `${
          data.fragments.script ?? ''
        }<script type="text/javascript" defer>function i(){window.location.href="https://faq.xing.com/de/browser"}function n(n){try{var t=n();if(t instanceof Promise){t.then(function(n){if(!n)i()},function(){return i()})}else if(!t){i()}}catch(f){i()}}n(function(){return typeof Array.prototype.flatMap==="function"});n(function(){return typeof Intl.Locale==="function"});</script>`,
      },
    };
  }
}
