const { Mixin } = require('@xingternal/hops');

class RecurringUserMixin extends Mixin {
  enhanceServerData(serverData, req, _res) {
    const cookies = req.cookies || {};
    const recurringUser = cookies['was_logged_in_once'] === 'true';

    return {
      ...serverData,
      recurringUser,
    };
  }
}

module.exports = RecurringUserMixin;
