import { Logger } from './logger';

const PRODUCTION_BASE_PATH = 'https://static.xingcdn.com';
const PREVIEW_BASE_PATH = 'https://preview-static.xingcdn.com';
const CURRENT_VERSION = 'v2';

const UrlMappings = {
  development: `${PREVIEW_BASE_PATH}/userconsent`,
  preview: `${PREVIEW_BASE_PATH}/userconsent`,
  production: `${PRODUCTION_BASE_PATH}/userconsent`,
};

export const ConfigurationService = {
  get: ({ formOfAddress = 'informal', locale, fetch, env = 'production' }) => {
    const fileName = `cookie-consent-${CURRENT_VERSION}-${formOfAddress}.${locale}`;
    const url = UrlMappings[env] || UrlMappings.production;

    const fileUrl = `${url}/${fileName}.json`;
    return fetch(fileUrl).then((resp) => {
      if (!resp.ok) {
        const context = '[CookieConsent:FetchConfig] get config fetch error';
        Logger.error({
          error: {
            type: resp.type,
            status: resp.status,
            text: resp.statusText,
          },
          context: context,
        });
        throw new Error('GET consent config failure');
      } else {
        return resp.json();
      }
    });
  },
};
