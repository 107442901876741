import { getQueryParams, mergeQueryParams } from '../lib/';

export const removeParams = (search, filter) => {
  const params = getQueryParams(search);
  const cleanedParams = params.filter(
    ([key, value]) => filter(key, value) === false
  );

  return mergeQueryParams(cleanedParams);
};

export default filter => {
  const { href, origin, pathname, search, hash } = window.location;

  if (!search) return href;

  const newSearch = removeParams(search, filter);

  if (newSearch === search) {
    return href;
  }

  const newHref =
    newSearch && newSearch !== '?'
      ? `${origin}${pathname}${newSearch}${hash}`
      : `${origin}${pathname}${hash}`;

  window.history.replaceState({}, null, newHref);

  return newHref;
};
