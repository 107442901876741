let API_VERSION = 'v0';
let TTT_VERSION = '0.0.0';

export default constants => {
  API_VERSION = constants.API_VERSION;
  TTT_VERSION = constants.TTT_VERSION;
};

const PARAM_REGEX = /^(sc_|fbclid|gclid|msclkid)/;

const CLIENTSIDE_VISITORCOOKIE_NAME = 'csod_visitor_id';
const CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST = ['csod.com', 'localhost'];
const DEDUPLICATION_ID_ALLOWLIST = ['wbm'];

const ABACUS_TRACKING_PATH = '/api/upgraded-umbrella/r';
const NWT_TRACKING_PATH = '/api/unified-underdog';

export {
  API_VERSION,
  TTT_VERSION,
  PARAM_REGEX,
  CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST,
  CLIENTSIDE_VISITORCOOKIE_NAME,
  DEDUPLICATION_ID_ALLOWLIST,
  ABACUS_TRACKING_PATH,
  NWT_TRACKING_PATH,
};
