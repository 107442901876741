import PropTypes from 'prop-types';

export const UserChoicesTypes = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
);

export const CategoryTypes = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  version: PropTypes.number,
  mandatory: PropTypes.bool,
});

export const ModalConfigurationTypes = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  acceptAllButton: PropTypes.string,
  acceptMandatoryButton: PropTypes.string,
  acceptSelectedButton: PropTypes.string,
  categories: PropTypes.arrayOf(CategoryTypes),
});
