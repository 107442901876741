import queryString from 'query-string';

const PARAMS_WHITELIST = ['register_redirect_to', 'sc_cmp'];

export default (registrationOptions = {}) => {
  if (typeof window === 'undefined') return {};

  const { location } = window;
  const search = queryString.parse(location.search);

  return PARAMS_WHITELIST.reduce((acc, name) => {
    if (search[name]) acc[name] = search[name];
    return acc;
  }, registrationOptions);
};
