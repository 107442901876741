import { Mixin } from '@xingternal/hops-mixin';

const ALTERING_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];
const COOKIE_REGEXP = /(?:^|;\s*)xing_csrf_token=([^;]+)/;
const HTTP_HEADER_KEY = 'X-CSRF-Token';

class HopsCsrfRuntimeMixin extends Mixin {
  constructor(...args) {
    super(...args);

    const { csrf: { trustedOrigins } = {} } = this.config;

    this.currentOrigin = window.location.origin;
    this.trustedOrigins = trustedOrigins && new RegExp(trustedOrigins);
  }

  methodRequiresCsrfToken(method = '') {
    return ALTERING_METHODS.includes(method.toUpperCase());
  }

  isTrustedOrigin(url) {
    const [origin] = /^[^:]+:\/\/[^/]+/.exec(url) || [];

    return (
      // the Request polyfill does not expand a relative URL in some cases
      // (e.g. IE11), so we need to check that here as well.
      !origin ||
      origin === this.currentOrigin ||
      (this.trustedOrigins ? this.trustedOrigins.test(origin) : false)
    );
  }

  getCsrfCookie() {
    const match = document.cookie.match(COOKIE_REGEXP);
    return match ? match[1] : '';
  }

  addCsrfHeader(...args) {
    const request = new Request(...args);
    const csrfCookie = this.getCsrfCookie();
    const headerIsRequired =
      this.isTrustedOrigin(request.url) &&
      this.methodRequiresCsrfToken(request.method);

    if (headerIsRequired && !request.headers.has(HTTP_HEADER_KEY)) {
      request.headers.set(HTTP_HEADER_KEY, csrfCookie);
    } else {
      request.headers.set(
        `${HTTP_HEADER_KEY}-HopsDebug`,
        `isRequired=${headerIsRequired},hasValue=${Boolean(csrfCookie)}`
      );
    }

    return request;
  }

  configureFetch(fetch) {
    return (...args) => {
      return fetch(this.addCsrfHeader(...args));
    };
  }
}

export default HopsCsrfRuntimeMixin;
