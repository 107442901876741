import { Mixin } from '@xingternal/hops-mixin';

class HopsXingOneConfigureFetchRuntimeMixin extends Mixin {
  configureFetch(fetch) {
    const { clientIp } = this;
    const graphqlUri =
      process.env.INTERNAL_GRAPHQL_URL || this.config.graphqlUri;
    const { pathname: graphqlPathname } = new URL(
      graphqlUri,
      'http://example.com'
    );
    const { hopsVersion } = this.getServerData();

    return (...args) => {
      const request = new Request(...args);

      if (request.url.endsWith(graphqlPathname)) {
        request.headers.set('xing-hops-version', hopsVersion);
        if (clientIp) {
          request.headers.set('xing-hops-client-ip', clientIp);
        }
      }

      return fetch(request);
    };
  }
}

export default HopsXingOneConfigureFetchRuntimeMixin;
