import { DEBUG, INFO, WARN, ERROR } from './constants';
import { getMinimumLogLevel, setMinimumLogLevel } from './helpers';
import console, { polyfill } from './console';

const logLevels = {
  [DEBUG]: 0,
  [INFO]: 1,
  [WARN]: 2,
  [ERROR]: 3,
};

const canLog = logLevel => {
  const minLevel = getMinimumLogLevel();

  return !(!minLevel || logLevels[logLevel] < logLevels[minLevel]);
};

const logMessage = (prefix, logLevel, method, ...args) => {
  if (!canLog(logLevel)) {
    return;
  }

  if (logLevel === ERROR) {
    console[method](...args);
  } else {
    const message =
      prefix +
      ' ' +
      args.map(a => (typeof a === 'object' ? JSON.stringify(a) : a)).join(' ');

    console[method](message);
  }
};

function createLogger(name, minLevel = DEBUG) {
  const prefix = `${name}:`;

  const logger = {};

  Object.keys(polyfill).forEach(method => {
    const logLevel = logLevels[method] !== undefined ? method : INFO;

    logger[method] = logMessage.bind(logger, prefix, logLevel, method);
  });

  logger.inspect = (logLevel, message, object) => {
    if (!canLog(logLevel)) {
      return;
    }

    logger.group(message);

    Object.keys(object)
      .filter(key => typeof object[key] !== 'function')
      .sort()
      .forEach(key => {
        console.log(`${key}: ${JSON.stringify(object[key])}`);
      });

    logger.groupEnd();
  };

  logger.prefix = prefix;

  return logger;
}

export default createLogger;
export { logLevels, logMessage, setMinimumLogLevel };
