import fetchJson from '@xingternal/malt-network/fetch-json';

import useWindowRegistrationOptions from './useWindowRegistrationOptions';

import { JSON_HEADERS } from './constants';

const isValidRecaptchaResponse = token =>
  typeof token === 'string' && token.length > 0;

const sanitizeValue = value =>
  typeof value === 'string' ? value.trim().replace(/\s{2,}/, ' ') : value;

const valuesToPayload = (
  {
    firstName,
    lastName,
    email,
    password,
    parsedCvId,
    tandcCheck,
    signupChannel,
    gRecaptchaResponse,
    skipEmailDomainCheck,
    checkSecondaryFields,
  },
  emailOnly
) => {
  const miniregPayload = {
    email: sanitizeValue(email),
    password: password,
    tandc_check: tandcCheck,
    signup_channel: signupChannel,
    skip_email_domain_check: skipEmailDomainCheck,
    check_secondary_fields: checkSecondaryFields,
    email_check_type: 'strict',
    ...(parsedCvId && { parsed_cv_id: sanitizeValue(parsedCvId) }),
    ...(emailOnly
      ? { email_only: true }
      : {
          first_name: sanitizeValue(firstName),
          last_name: sanitizeValue(lastName),
        }),
  };

  const registrationOptions = useWindowRegistrationOptions();

  return Object.assign(
    {},
    registrationOptions,
    { signup_minireg: miniregPayload },
    isValidRecaptchaResponse(gRecaptchaResponse)
      ? { 'g-recaptcha-response': gRecaptchaResponse }
      : {}
  );
};

const createSignup = async (externalUrl, values, emailOnly) =>
  fetchJson(`${externalUrl}/welcome/api/signup`, {
    headers: JSON_HEADERS,
    method: 'POST',
    body: JSON.stringify(valuesToPayload(values, emailOnly)),
  });
  
export { createSignup };
