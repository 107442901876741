export default class MaltResponseError extends Error {
  constructor(message, response, jsonResponse) {
    super(message);

    this.name = this.constructor.name;
    this.response = response;
    this.jsonResponse = jsonResponse;

    // Workaround to make `instanceof MaltResponseError` work in transpiled ES5
    Object.setPrototypeOf(this, MaltResponseError.prototype);
  }
}
