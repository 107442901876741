import queryString from 'query-string';

const PARAMS_WHITELIST = ['dest_url', 'set_language', 'level', 'state'];

export default loginOptions => {
  if (typeof window === 'undefined') return {};

  const { location } = window;
  const search = queryString.parse(location.search);

  return PARAMS_WHITELIST.reduce((acc, name) => {
    if (search[name]) acc[name] = search[name];
    return acc;
  }, loginOptions || {});
};
