// @ts-check

/**
 * @type {typeof import('@xingternal/hops-user-auth').LOGIN_STATE}
 */
const LOGIN_STATE = {
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  SOFT_LOGGED_IN: 'SOFT_LOGGED_IN',
};

module.exports = LOGIN_STATE;
