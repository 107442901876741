function allowedToLog() {
  const { hostname } = window.document.location;

  return !hostname.startsWith('admin');
}

function shouldBeTracked(response) {
  return (
    response.headers.has('X-Logjam-Request-Id') &&
    response.headers.has('X-Logjam-Request-Action')
  );
}

function buildTrackParams(id, action, location, timings, params = {}) {
  return {
    v: 1,
    logjam_request_id: id,
    logjam_action: action,
    url: location,
    rts: timings.join(','),
    ...params,
    _: Date.now(),
  };
}

function buildLoadTimingsParams(id, action, params = {}) {
  const { document, performance, screen } = window;
  const { navigation = { redirectCount: 0 }, timing = {} } = performance || {};

  const timings = [
    'navigationStart',
    'fetchStart',
    'domainLookupStart',
    'domainLookupEnd',
    'connectStart',
    'connectEnd',
    'requestStart',
    'responseStart',
    'responseEnd',
    'domLoading',
    'domInteractive',
    'domContentLoadedEventStart',
    'domContentLoadedEventEnd',
    'domComplete',
    'loadEventStart',
    'loadEventEnd',
  ].reduce((list, key) => list.concat(timing[key] || 0), []);

  const query = document.location.pathname;

  return buildTrackParams(id, action, query, timings, {
    ...params,
    screen_height: screen.height,
    screen_width: screen.width,
    html_nodes: document.getElementsByTagName('*').length,
    script_nodes: document.scripts.length,
    style_nodes: document.styleSheets.length,
    redirect_count: navigation.redirectCount || 0,
  });
}

function buildFetchTimingParams(response, start, end, params = {}) {
  const { url, headers } = response;

  const id = headers.get('X-Logjam-Request-Id');
  const action = headers.get('X-Logjam-Request-Action');

  const { pathname } = Object.assign(document.createElement('a'), {
    href: url,
  });

  return buildTrackParams(id, action, pathname, [start, end], params);
}

function track(endpoint, params) {
  const url = `${endpoint}?${new URLSearchParams(params).toString()}`;

  new Image().src = url;

  return url;
}

function trackLoadTimings(endpoint, ...args) {
  return track(endpoint, buildLoadTimingsParams(...args));
}

function trackFetchTiming(endpoint, ...args) {
  return track(endpoint, buildFetchTimingParams(...args));
}

export {
  allowedToLog,
  shouldBeTracked,
  buildTrackParams,
  buildLoadTimingsParams,
  buildFetchTimingParams,
  trackLoadTimings,
  trackFetchTiming,
};
