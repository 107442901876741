import { useEffect } from 'react';
import devNote from '@xingternal/dev-note';
import { useXingHub } from '@xingternal/xing-hub';

/* imported from entry to enable better mocking */
import sendDsTracking from '../sendDsTracking';

export default function useSendDsTracking(userId) {
  const { fetch } = useXingHub();
  useEffect(() => {
    if (userId) {
      sendDsTracking(userId, fetch).catch(devNote.error);
    }
  }, [userId, fetch]);
}
