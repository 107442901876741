import React, { Component, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { PopUpWindow } from '@xingternal/pop-up-window';
import { ttt } from '@xingternal/malt-tracking';
import { withConfig } from '@xingternal/hops';

import styles from './CookieConsentModal.css';

import CookieConsentBannerContent from './CookieConsentBannerContent';

import TttService from './data-sources/ttt-service';

import {
  getLanguage,
  newConsentLocales,
  isXingbox,
  isXingPreview,
} from './utils';

import { TrackingConsent } from './data-sources/tracking-service';
import { ConfigurationService } from './configuration-service';
import { supportedRunningPlatform } from './supported-platform';
import { Logger } from './logger';
import { useCookieConsentContext } from './CookieConsentProvider';

import { CookieSettingsContainer } from './CookieSettingsContainer';

const adjustEnv = (env) => {
  if (isXingbox()) {
    return 'development';
  } else if (isXingPreview()) {
    return 'preview';
  }

  return env;
};

const fetchConfiguration = ({
  locale,
  fetch = global.fetch,
  formOfAddress,
}) => {
  return ConfigurationService.get({
    locale: locale,
    fetch,
    env: adjustEnv(process.env.NODE_ENV),
    formOfAddress: formOfAddress,
  });
};

// TODO: It's not clear what setUserChoices is for (its for the proivider)
// and set privacy settings.
const acceptSelected =
  (
    setShowModal,
    setShowSettings,
    setUserChoices,
    trackingService,
    setPrivacySettings
  ) =>
  async (userChoices) => {
    setShowSettings(false);
    setShowModal(false);

    const withVersion = trackingService.acceptSelected(userChoices);
    setUserChoices(withVersion);
    trackingService.setUserConsent(withVersion);
    setPrivacySettings(withVersion);
  };

const acceptAll =
  (
    setShowSettings,
    setShowModal,
    setUserChoices,
    trackingService,
    setPrivacySettings
  ) =>
  () => {
    setShowSettings(false);
    setShowModal(false);

    const all = trackingService.allCategoriesAccepted();
    trackingService.setUserConsent(all);
    setUserChoices(all);
    setPrivacySettings(all);
  };

/**
 * This banner is rendered by default in xing-frame-provider.
 * If rendered by default, it'll cause an annoyance to other teams at xing
 *
 * So, we don't render the banner in the following scenarios:
 * 1. When server-side rendered (checking global.document)
 * 2. When executing jest tests (NODE_ENV=true) unless injected a hops config as true
 * 3. When running in xingboxes or *.env.xing.com because of browser/integration specs
 * 4. In localhost so devs don't need to skip the banner everytime
 *
 * Scenarios where the banner should be rendered:
 * 1. When specified in hops config with the key enableConsentBanner
 * 2. In preview/production
 */
const skipBanner = (hopsConfig, blockBanner, forceShow) => {
  // Undocumented way to skip the banner via the client
  // Only messenger component is gonna use this flag, that's why we don't add to the docs
  // Usual clients can use disableBanner prop normally
  // https://jira.xing.hh/browse/UTEC-1088
  if (blockBanner) {
    return true;
  }

  if (forceShow) {
    return false;
  }

  if (!global.document) {
    return true;
  }

  if (!supportedRunningPlatform(global.navigator)) {
    return true;
  }

  const enableConsentBanner =
    'enableConsentBanner' in hopsConfig
      ? hopsConfig.enableConsentBanner
      : undefined;

  if (enableConsentBanner === false || enableConsentBanner === 'false') {
    return true;
  }

  if (enableConsentBanner === true || enableConsentBanner === 'true') {
    return false;
  }

  if (global.window.ConsentBrowserTest || global.window.Cypress) {
    return true;
  }

  const externalUrl =
    (hopsConfig._env && hopsConfig._env.EXTERNAL_URL) ||
    global.window.location.host;

  if (process.env.NODE_ENV === 'test' && !global.testCookieConsent) {
    return true;
  }

  if (
    /\.[k]?env.xing.com|localhost:|^192\.168|http:\/\/application$/.test(
      externalUrl
    )
  ) {
    return true;
  }

  return false;
};

const getOverflowValue = () => {
  if (!global.document) {
    return null;
  }
  return global.document.body && global.document.body.style.overflow;
};

const setOverflowValue = (overflowValue) => {
  if (!global.document) {
    return;
  }

  if (global.document.body) {
    global.document.body.style.overflow = overflowValue;
  }
};

const CookieConsentModal = ({
  lang,
  formOfAddress,
  disableBanner,
  blockBanner,
  config: hopsConfig = {},
  forceShow,
  customConfiguration,
}) => {
  const isGloballyDisabled = skipBanner(hopsConfig, blockBanner, forceShow);
  const {
    showModal,
    setShowModal,
    showSettings,
    setShowSettings,
    setUserChoices,
  } = useCookieConsentContext();
  const [privacySettings, setPrivacySettings] = useState(null);
  const [configuration, setConfiguration] = useState(null);
  const [trackingConsentService, setService] = useState(null);
  const [tttService, setTttService] = useState(null);
  const [initialOverflow] = useState(getOverflowValue());

  useEffect(() => {
    if (isGloballyDisabled) {
      return;
    }

    setTttService(new TttService(ttt));

    const locale = getLanguage(
      lang,
      global.document,
      global.location,
      'advanced'
    );
    if (!customConfiguration) {
      fetchConfiguration({ locale: locale, formOfAddress: formOfAddress })
        .then((response) => {
          setConfiguration(response);
        })
        .catch(() => {
          // https://jira.xing.hh/browse/UTEC-1058
          // Ignore these errors. Majority of them are coming from ad blockers blocking this service
        });
    } else {
      setConfiguration(customConfiguration);
    }
  }, []);

  useEffect(() => {
    if (isGloballyDisabled) {
      return;
    }

    if (configuration) {
      const service = new TrackingConsent({
        fetch: global.fetch,
        bannerConfig: configuration,
      });
      setService(service);
    }
  }, [configuration]);

  useEffect(() => {
    if (isGloballyDisabled) {
      return;
    }

    if (showModal) {
      tttService.trackViewBanner();
    }
  }, [showModal]);

  useEffect(() => {
    if (isGloballyDisabled) {
      return;
    }

    if (showModal && showSettings) {
      setOverflowValue(initialOverflow);
    } else if (showModal && !showSettings) {
      setOverflowValue('hidden');
    } else {
      setOverflowValue(initialOverflow);
    }
  }, [showModal, showSettings]);

  useEffect(() => {
    if (isGloballyDisabled) {
      return;
    }

    if (!trackingConsentService) {
      return;
    }

    trackingConsentService.getUserConsent().then((currentConsent) => {
      if (trackingConsentService.userConsentRequired(currentConsent)) {
        // Once the user consent is required
        // Update the cookie so stm gets the user consent as empty.
        // This is because we need the user to re-optin
        setPrivacySettings({});
        if (!disableBanner) {
          trackingConsentService.resetUserConsent();
          setShowModal(true);
        }
      } else if (
        trackingConsentService.shouldShowUserConsent(global.window.location)
      ) {
        setPrivacySettings(currentConsent);
        if (!disableBanner) {
          setShowModal(true);
        }
      } else {
        setUserChoices(currentConsent);
        setPrivacySettings(currentConsent);
      }
    });
  }, [trackingConsentService]);

  if (isGloballyDisabled) {
    return null;
  }

  if (disableBanner && !showModal) {
    return null;
  }

  if (showModal) {
    return (
      <React.Fragment>
        <PopUpWindow
          className={styles.popUpWindow}
          onDimmerClick={() => {
            setOverflowValue('hidden');
          }}
          isShown={showModal && !showSettings}
          noPadding
        >
          <CookieConsentBannerContent
            tttService={tttService}
            acceptSelected={acceptSelected(
              setShowModal,
              setShowSettings,
              setUserChoices,
              trackingConsentService,
              setPrivacySettings
            )}
            acceptAll={acceptAll(
              setShowSettings,
              setShowModal,
              setUserChoices,
              trackingConsentService,
              setPrivacySettings
            )}
            setShowSettings={setShowSettings}
            resources={configuration}
            userChoices={privacySettings}
          />
        </PopUpWindow>
        <CookieSettingsContainer
          tttService={tttService}
          acceptSelected={acceptSelected(
            setShowModal,
            setShowSettings,
            setUserChoices,
            trackingConsentService,
            setPrivacySettings
          )}
          acceptAll={acceptAll(
            setShowSettings,
            setShowModal,
            setUserChoices,
            trackingConsentService,
            setPrivacySettings
          )}
          resources={configuration}
          userChoices={privacySettings}
        />
      </React.Fragment>
    );
  }

  return null;
};

CookieConsentModal.propTypes = {
  lang: PropTypes.oneOf(newConsentLocales),
  setUserChoices: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  disableBanner: PropTypes.bool,
  formOfAddress: PropTypes.oneOf(['formal', 'informal']),
  config: PropTypes.object,
  blockBanner: PropTypes.bool,
  forceShow: PropTypes.bool,
  customConfiguration: PropTypes.object,
};

CookieConsentModal.defaultProps = {
  setUserChoices: () => {},
  setShowModal: () => {},
  showModal: false,
  config: {},
  disableBanner: false,
  blockBanner: false,
  forceShow: false,
  customConfiguration: null,
};

class CookieConsentError extends Component {
  state = { hasError: false, initialOverflow: '' };

  componendDidMount() {
    if (global.document) {
      this.setState({ initialOverflow: getOverflowValue() });
    }
  }

  componentDidCatch(error) {
    // Clean up global style set by CookieConsent when banner is shown
    if (global.document) {
      setOverflowValue(this.state.initialOverflow);
    }

    // eslint-disable-next-line no-console
    console.error('CookieConsentModal encountered an error: ' + error);

    const err = Object.getOwnPropertyNames(error);
    const context = 'CookieConsent -- componentDidCatch';
    Logger.error({ error: err, context: context, raw: error });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    } else {
      return <CookieConsentModal {...this.props} />;
    }
  }
}
export default withConfig ? withConfig(CookieConsentError) : CookieConsentError;
