function configureFetchWithSentry(fetch, Sentry, { graphqlUri } = {}) {
  return async (input, init) => {
    const request = new Request(input, init);

    const isGraphqlQuery =
      graphqlUri && request.url === graphqlUri && request.method === 'POST';

    if (isGraphqlQuery) {
      const { query } = await request
        .clone()
        .json()
        .catch(() => ({}));

      Sentry.addBreadcrumb({
        type: 'query',
        category: 'graphql',
        message: query,
      });
    }

    const response = await fetch(request);

    if (isGraphqlQuery) {
      const { errors } = await response
        .clone()
        .json()
        .catch(() => ({}));

      if (Array.isArray(errors) && errors.length > 0) {
        Sentry.addBreadcrumb({
          type: 'error',
          level: 'error',
          category: 'graphql',
          message: JSON.stringify(errors),
        });
      }
    }

    return response;
  };
}

export { configureFetchWithSentry };
