import React from 'react';
import PropTypes from 'prop-types';

import NoCookies from './NoCookies';
import NoJavascript from './NoJavascript';

const Fallback = ({ children }) => (
  <NoJavascript>
    <NoCookies>{children}</NoCookies>
  </NoJavascript>
);

Fallback.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Fallback;
