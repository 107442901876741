export const ERROR_MESSAGES = {
  badFile: 'badFileErrorLabel',
  common: 'commonErrorLabel',
};

export const ACCEPTED_FILE_FORMATS = ['.pdf', '.docx', '.pages'];

export const MAX_ALLOWED_CV_SIZE_IN_MB = 100;

export const UPLOAD_FILE_APPLICATION = 'CV_UPLOAD';

export const PROP_CHANNEL = 'wbm/Welcome';
