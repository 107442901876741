import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { spaceXL } from '@xingternal/tokens';

const StyledRecaptchaContainer = styled.div`
  margin: ${spaceXL} 0;
`;

const Recaptcha = ({ googleSiteKey, language, onSubmit, setFieldValue }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?hl=${language}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  if (typeof window !== 'undefined') {
    window.submitRecaptcha = token => {
      setFieldValue('gRecaptchaResponse', token);
      // We need to use timeout to make sure onSubmit is called async
      // https://github.com/jaredpalmer/formik/issues/529
      if (typeof onSubmit === 'function') setTimeout(onSubmit, 50);
    };
  }

  return (
    <StyledRecaptchaContainer
      className={'g-recaptcha'}
      data-sitekey={googleSiteKey}
      data-action={'signup'}
      data-callback={'submitRecaptcha'}
    />
  );
};

Recaptcha.propTypes = {
  googleSiteKey: PropTypes.string,
  language: PropTypes.string,
  onSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default Recaptcha;
