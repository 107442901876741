import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { ttt } from '@xingternal/malt-tracking';
import { createOnlyfySignup} from './actions';

import { Headline, BodyCopy } from '@xingternal/typography';
import { FormField } from '@xingternal/text-field';
import { Checkbox } from '@xingternal/checkbox';
import { TextButton } from '@xingternal/button';
import { IconApple } from '@xingternal/icons';
import IconGoogle from './IconGoogle';

import PasswordInput from './../password-input/PasswordInput';
import FormMessage from './../form-message/FormMessage';
import PartnerNotice from './PartnerNotice';
import { spaceM, spaceL, spaceXL } from '@xingternal/tokens';

import {
  HideSmallerThanTablet,
  InputActions,
  InputWrapper,
  SubmitButton,
  TextCenter,
  Label,
} from './../styled';

import useSignupLink from './useSignupLink';

import messages from './messages';

const clearError = (fieldName, errors) =>
  Object.keys(errors).reduce((acc, field) => {
    if (field === fieldName) return acc;
    acc[field] = errors[field];
    return acc;
  }, {});

const View = ({
  errors,
  externalUrl,
  externalLoginUrl,
  headlineMessage,
  formRef,
  loggedOutSid,
  handleBlur,
  handleChange,
  handleSubmit,
  intl,
  noHeadline,
  noSignupLink,
  isSubmitting,
  setErrors,
  values: { username, password, perm },
  authPartner,
  isLoginDomain,
  showGoogleSignIn = true,
  showAppleSignIn = true,
  usernamePlaceholderMessage = null,
  passwordPlaceholderMessage = null,
  showTroubleLoggingInLink = true,
  onlyfyToken = null,
}) => {
  const location = useLocation();
  const [onlyfyRegInProgress, setOnlyfyRegInProgress] = React.useState(false);

  const maybeError = property => {
    if (!errors[property]) return null;

    return typeof errors[property] === 'string'
      ? errors[property]
      : intl.formatMessage(errors[property]);
  };

  const onHandleChange = e => {
    handleChange(e);

    const fieldName = e.target.name;
    setErrors(clearError(fieldName, errors));
  };

  const RecoveryLinkTag = isLoginDomain ? Link : 'a';
  const recoveryLinkProps = isLoginDomain
    ? { to: '/recovery' }
    : { href: `${externalLoginUrl}/recovery` };

  const params = new URLSearchParams(location.search)
  const dest_url = params.get('dest_url')

  const handleOnlyfyRegister = async () => {
    // User pressed the registration button in Onlyfy mode.
    // Automatically create their account using the Onlyfy endpoint

    setOnlyfyRegInProgress(true);

    try {

      const onlyfyPayload = {
        state: onlyfyToken,
        logged_out_sid: loggedOutSid,
      };
      await createOnlyfySignup(externalUrl, onlyfyPayload);
      setOnlyfyRegInProgress(false);

    }catch(e) {
      setOnlyfyRegInProgress(false);
      setErrors({
        ...errors,
        general: messages.submitRequestError
      });
    }
  };

  return (
    <React.Fragment>
      {!noHeadline && (
        <Headline size="xxlarge" as="h2" style={{ marginBottom: spaceXL }}>
          {intl.formatMessage(headlineMessage || messages.headline)}
        </Headline>
      )}

      <PartnerNotice partner={authPartner} />

      <form ref={formRef} method={'POST'} onSubmit={handleSubmit}>
        <InputWrapper>
          <FormField
            type="email"
            autoFocus
            autoCapitalize={'none'}
            size="medium"
            error={maybeError('username')}
            helperText={maybeError('username')}
            name={'username'}
            id={'username'}
            onBlur={handleBlur}
            onChange={onHandleChange}
            placeholder={usernamePlaceholderMessage || intl.formatMessage(messages.usernameLabel)}
            value={username}
            data-qa={'username'}
          />
        </InputWrapper>

        <InputWrapper>
          <PasswordInput
            name={'password'}
            id={'password'}
            onBlur={handleBlur}
            onChange={onHandleChange}
            error={maybeError('password')}
            helperText={maybeError('password')}
            placeholder={passwordPlaceholderMessage || intl.formatMessage(messages.passwordLabel)}
            value={password}
            data-qa={'password'}
          />
        </InputWrapper>

        <InputActions>
          <HideSmallerThanTablet>
            <Checkbox
              id={'perm'}
              name={'perm'}
              onChange={onHandleChange}
              checked={perm}
              data-qa={'perm'}
            />
            <Label htmlFor={'perm'}>
              {intl.formatMessage(messages.rememberMe)}
            </Label>
          </HideSmallerThanTablet>

          {showTroubleLoggingInLink ? <RecoveryLinkTag
            {...recoveryLinkProps}
            data-qa={'login-problems-link'}
          >
            <BodyCopy as="span" size="medium">
              {intl.formatMessage(messages.problemsLoggingIn)}
            </BodyCopy>
          </RecoveryLinkTag>
          : null}
        </InputActions>

        {errors.general && (
          <FormMessage error={maybeError('general')} />
        )}

        <BodyCopy
          size="small"
          style={{ marginTop: spaceM, marginBottom: spaceL }}
        >
          <FormattedMessage
            {...messages.tandcDescription}
            values={{
              tandc: (
                <a href={'https://www.xing.com/terms'} target={'_blank'}>
                  <FormattedMessage {...messages.tandcLabel} />
                </a>
              ),
              dataprotection: (
                <a
                  href={intl.formatMessage(messages.dataprotectionLink)}
                  target={'_blank'}
                >
                  <FormattedMessage {...messages.dataprotectionLabel} />
                </a>
              ),
            }}
          />
        </BodyCopy>

        <SubmitButton
          variant="primary"
          size="medium"
          loading={isSubmitting}
          onClick={handleSubmit}
          type={'submit'}
        >
          {intl.formatMessage(messages.submitLabel)}
        </SubmitButton>

        {showGoogleSignIn ? <TextCenter
        style={{ marginTop: spaceXL }}>
          <SubmitButton
            variant="secondary"
            size="medium"
            icon={IconGoogle}
            href={`${externalUrl}/signup/oauth?caller=login_page&dest_url=${dest_url}&signup_channel=oauth_google`}
            onClick={() => {
              ttt.pageview(
                'wbm/Welcome',
                'wbm/Welcome/login/google',
                {},
                {
                  PropTrackAction: 'lp_google_click',
                }
              );
            }}
          >
            {intl.formatMessage(messages.continueWithGoogle)}
          </SubmitButton>
        </TextCenter> : null}
        
        {showAppleSignIn ? <TextCenter
          style={{ marginTop: spaceXL }}>
          <SubmitButton
            variant="secondary"
            size="medium"
            icon={IconApple}
            href={`${externalUrl}/signup/oauth/apple?caller=login_page&dest_url=${dest_url}&signup_channel=oauth_apple`}
            onClick={() => {
              ttt.pageview(
                'wbm/Welcome',
                'wbm/Welcome/login/apple',
                {},
                {
                  PropTrackAction: 'lp_apple_click',
                }
              );
            }}
          >
            {intl.formatMessage(messages.continueWithApple)}
          </SubmitButton>
          </TextCenter> : null}

        {!noSignupLink && (
          <TextCenter style={{ marginTop: spaceM }}>
            <TextButton
              sizeonWide="medium"
              sizeonconfined="medium"
              size="small"
              href={onlyfyToken ? undefined : useSignupLink(externalUrl, location)}
              onClick={onlyfyToken ? handleOnlyfyRegister : undefined}
              type="button"
              disabled={onlyfyRegInProgress}
            >
              {intl.formatMessage(messages.registerNow)}
            </TextButton>
          </TextCenter>
        )}
      </form>
    </React.Fragment>
  );
};

View.propTypes = {
  errors: PropTypes.object,
  externalLoginUrl: PropTypes.string.isRequired,
  externalUrl: PropTypes.string.isRequired,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  loggedOutSid: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  intl: PropTypes.any,
  isSubmitting: PropTypes.bool,
  noHeadline: PropTypes.bool,
  noSignupLink: PropTypes.bool,
  setErrors: PropTypes.func,
  isLoginDomain: PropTypes.bool,
  headlineMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  authPartner: PropTypes.shape({
    website: PropTypes.string,
    name: PropTypes.string,
  }),
  showAppleSignIn: PropTypes.bool,
  showGoogleSignIn: PropTypes.bool,
  usernamePlaceholderMessage: PropTypes.string,
  passwordPlaceholderMessage: PropTypes.string,
  onlyfyToken: PropTypes.string,
};

export default injectIntl(View);
