import { CLIENTSIDE_VISITORCOOKIE_NAME } from '../constants';
import { getCookie, setCookie } from './cookies';
import uuid from './uuid';
import getSecondLevelDomain from './getSecondLevelDomain';

const getClientsideVisitorId = () => {
  // get or generate visitorId
  const visitorId = getCookie(CLIENTSIDE_VISITORCOOKIE_NAME) || uuid();

  // set visitor_id cookie
  const hostname = getSecondLevelDomain();
  const options = {
    path: '/',
  };

  if (hostname !== null) {
    options.domain = hostname;
  }
  setCookie(
    CLIENTSIDE_VISITORCOOKIE_NAME,
    visitorId,
    1000 * 60 * 60 * 24 * 365 * 1,
    options
  );

  return visitorId;
};
export default getClientsideVisitorId;
