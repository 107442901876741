import { defineMessages } from 'react-intl';

const noCookiesMessages = defineMessages({
  title: {
    id: 'BREWERY_NOCOOKIES_TITLE',
    defaultMessage: 'Die Nutzung von XING erfordert cookies',
  },
  paragraph: {
    id: 'BREWERY_NOCOOKIES_PARAGRAPH',
    defaultMessage:
      'Erlauben Sie Cookies in ihrem Browser, denn nur so sehen Sie alle Inhalte. Wie Sie das genau machen, lesen Sie auf:',
  },
  linkTarget: {
    id: 'BREWERY_NOCOOKIES_LINK_TARGET',
    defaultMessage: 'https://faq.xing.com/node/67945',
  },
  linkText: {
    id: 'BREWERY_NOCOOKIES_LINK_TEXT',
    defaultMessage: 'Cookies aktivieren',
  },
});

const noJavascriptMessages = defineMessages({
  title: {
    id: 'BREWERY_NOJAVASCRIPT_TITLE',
    defaultMessage: 'Xing braucht JavaScript',
  },
  paragraph: {
    id: 'BREWERY_NOJAVASCRIPT_PARAGRAPH',
    defaultMessage:
      'Aktivieren Sie bitte JavaScript, denn nur so sehen Sie alle unsere Inhalte. Wie Sie das genau machen, lesen Sie auf:',
  },
  linkTarget: {
    id: 'BREWERY_NOJAVASCRIPT_LINK_TARGET',
    defaultMessage: 'https://faq.xing.com/node/67945',
  },
  linkText: {
    id: 'BREWERY_NOJAVASCRIPT_LINK_TEXT',
    defaultMessage: 'JavaScript aktivieren',
  },
});

export { noCookiesMessages, noJavascriptMessages };
