import { hostResolver } from './data-sources/host-resolver';

export class Logger {
  // @input err: Error instance
  static error(err) {
    err['href'] = window.location && window.location.href;
    if (typeof window === 'undefined' || !window.fetch) {
      // eslint-disable-next-line no-console
      console.warn('[Logger] Logger does not work server-side');
    }
    if (this.logPath()) {
      window
        .fetch(this.logPath(), {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(err),
        })
        .then((response) => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    } else {
      // eslint-disable-next-line no-console
      console.warn('[Logger] Could not determine host');
    }
  }

  static logPath() {
    this.host = this.host || hostResolver();
    if (!this.host) {
      return null;
    }

    return `${this.host}/legal/api/user_consent/log`;
  }
}
