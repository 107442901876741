import gql from 'graphql-tag';

export const DataScienceTrackingFullFormat = gql`
  mutation DataScienceTrackingFullFormat(
    $input: DataScienceFullTrackingInput!
  ) {
    dataScienceTrackingFullFormat(input: $input) {
      error {
        message
      }
      success {
        message
      }
    }
  }
`;
