import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { createSignup } from './actions';

import { MAP_RESPONSE_FIELDS_TO_NAMES, INITIAL_FORM_VALUES } from './constants';

import View from './View';

import messages from './messages';

const reduceErrors = (
  errors,
  values = {
    skipEmailDomainCheck: false,
    checkSecondaryFields: true,
  }
) => {
  return Object.keys(errors || {}).reduce((acc, errorKey) => {
    const fieldName = MAP_RESPONSE_FIELDS_TO_NAMES[errorKey];

    if (!fieldName) return acc;

    if (values.skipEmailDomainCheck && fieldName === 'emailDomain') return acc;
    if (
      !values.checkSecondaryFields &&
      fieldName === 'email' &&
      errors[errorKey] === 'ERROR_REGISTER_EMAIL_SECONDARY'
    )
      return acc;
    acc[fieldName] = errors[errorKey];

    return acc;
  }, {});
};

const RegistrationForm = ({
  googleSiteKey,
  externalUrl,
  language,
  signupChannel,
  onShowRecaptcha = () => null,
  onError = () => null,
  onSubmit = null,
  onSubmitSuccess = null,
  headlineMessage = null,
  ctaMessage = null,
  neededFields = ['firstName', 'lastName', 'email', 'password', 'tandcCheck'],
  emailOnly = false,
  enabledCheck = null,
  hideHeadline = false,
  prefilledValues = {},
  onSubmitValidateErrors = () => {},
  cvUploadConfig,
  enableGoogleSignup,
  enableAppleSignup,
}) => {
  const initialFormValues = {
    ...INITIAL_FORM_VALUES,
    signupChannel,
    ...prefilledValues,
    ...(cvUploadConfig && cvUploadConfig.source && cvUploadConfig.flowIdentifier && { parsedCvId: '' }),
  };

  const [emailDomainError, setEmailDomainError] = useState(false);
  const [secondaryEmailError, setSecondaryEmailError] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const submit = async (values, actions) => {
    const { setSubmitting, setErrors } = actions;
    const payload = { ...values, signupChannel };
    setShowRecaptcha(false);
    try {
      const result = await createSignup(externalUrl, payload, emailOnly);
      const { success, target, captcha } = result;
      if (success && captcha) {
        setShowRecaptcha(true);
        return new Promise(() => {
          onShowRecaptcha();
        });
      }

      if (success && typeof onSubmitSuccess === 'function') {
        return new Promise(() => {
          onSubmitSuccess();
        });
      }

      if (success && target) {
        return new Promise(() => {
          window.location = target;
        });
      }
    } catch (e) {
      let originalErrors =  e.jsonResponse.errors;
      let reducedErrors;
      try {
        reducedErrors = reduceErrors(originalErrors, values);
        setEmailDomainError(originalErrors.emailDomain || values.skipEmailDomainCheck);
        if (values.checkSecondaryFields)
          setSecondaryEmailError(
            reducedErrors.email === 'ERROR_REGISTER_EMAIL_SECONDARY'
          );

        if (reducedErrors.base === "XE_GENERIC_ERROR")
          reduceErrors = {
            base: messages.submitRequestError,
          };

      } catch (e) {
        reducedErrors = {
          base: messages.submitRequestError,
        };
      }

      setSubmitting(false);
      setShowRecaptcha(false);
      setErrors(reducedErrors);

      onError('create', originalErrors);
    }
  };

  const viewProps = {
    language,
    googleSiteKey,
    showRecaptcha,
    headlineMessage,
    hideHeadline,
    ctaMessage,
    neededFields,
    enabledCheck,
    emailOnly,
    emailDomainError,
    setEmailDomainError,
    onSubmitValidateErrors,
    secondaryEmailError,
    setSecondaryEmailError,
    cvUploadConfig,
    externalUrl,
    enableGoogleSignup,
    enableAppleSignup,
    signupChannel
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSubmit || submit}
      validateOnChange={false}
      validateOnBlur
    >
      {formikProps => <View {...formikProps} {...viewProps} />}
    </Formik>
  );
};

RegistrationForm.propTypes = {
  /** EXTERNAL_URL - found in shared-dynamic-config */
  externalUrl: PropTypes.string.isRequired,
  /** ACCOUNT_GOOGLE_CAPTCHA_SITE_KEY - found in shared-dynamic-config  */
  googleSiteKey: PropTypes.string.isRequired,
  /** iso1 language code - read it from hops `useConfig();` */
  language: PropTypes.string.isRequired,
  /** signup_channel - usually a product or campaign name */
  signupChannel: PropTypes.string.isRequired,
  /** callback triggered before displaying recaptcha */
  onShowRecaptcha: PropTypes.func,
  /** error callback - returns error events 'validate' or
   * 'create' and signup errors: (event, errors) => handleOnError()
   * */
  onError: PropTypes.func,
  /** react-intl shaped message `{id: id, message: message}`, displayed above form */
  headlineMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  /** when set to true, no headline is rendered above the form */
  hideHeadline: PropTypes.bool,
  /** react-intl shaped message `{id: id, message: message}`, displayed in CTA button */
  ctaMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  /** prefilled form values - when the parent can supply some of the values */
  prefilledValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    tandcCheck: PropTypes.string,
    signupChannel: PropTypes.string,
  }),
  /** [DANGER] method executed onChange that dis- or enables the submit button when returning true or false: `(values) => true` */
  enabledCheck: PropTypes.func,
  /** [DANGER] list of fields that should be displayed */
  neededFields: PropTypes.arrayOf(PropTypes.string),
  /** [DANGER] overwrite form validation: `(values) => ({email: "error"})` */
  onValidate: PropTypes.func,
  /** [DANGER] overwrite form submission: `(values, formikActions) => {...}` */
  onSubmit: PropTypes.func,
  /** [DANGER] when true, hides first and last name fields */
  emailOnly: PropTypes.bool,
  /** [DANGER] Overrides the success behavior in the form submit callback */
  onSubmitSuccess: PropTypes.func,
  /** Catch errors that prevent submission */
  onSubmitValidateErrors: PropTypes.func,
  /** When filled, shows the "register via CV" section */
  cvUploadConfig: PropTypes.shape({
    source: PropTypes.string,
    flowIdentifier: PropTypes.string,
  }),
  /** Enable continue with Google button. Not available when there is the possibility of cv upload (for now) */
  enableGoogleSignup: PropTypes.bool,
  /** Enable continue with Apple button. Not available when there is the possibility of cv upload (for now) */
  enableAppleSignup: PropTypes.bool,
};

export default RegistrationForm;
