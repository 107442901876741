function getQueryParams(url) {
  if (!url) {
    return [];
  }

  url = url.substr(url.indexOf('?'));

  const regex = /[?&]([^=?&#]+)(?:=([^?&#]*))?/g;
  const params = [];
  let match;

  while ((match = regex.exec(url)) !== null) {
    const key = match[1];
    const value = match[2];

    params.push([decodeURIComponent(key), value && decodeURIComponent(value)]);
  }

  return params;
}

export default getQueryParams;
