// Map the current running domain to the correct api domain
// responsible to set the cookie. Each domain
// has its own alias that ends up in the same backend api
// (legal pages API).
const HostLookupTable = [
  // Preview hosts
  [new RegExp('.*preview.xing.com'), 'https://preview-consent.xing.com'],
  [
    new RegExp('.*staging.qa.amiando-qa.com'),
    'https://preview-consent.xing-events.com',
  ],
  [
    new RegExp('.*preview.xing-news.com'),
    'https://preview-consent.xing-news.com',
  ],
  [new RegExp('.*preview.new-work.se'), 'https://preview-consent.new-work.se'],
  [
    new RegExp('.*preview.lebenslauf.com'),
    'https://preview-consent.lebenslauf.com',
  ],
  [
    new RegExp('.*preview.anschreiben.com'),
    'https://preview-consent.anschreiben.com',
  ],
  [
    new RegExp('.*preview-bewerbung.xing.com'),
    'https://preview-consent.bewerbung.com',
  ],
  [new RegExp('.*kununu.it'), 'https://preview-consent.kununu.it'],

  // Eventually only stage.hallofreelancer will
  // remain. hallofreelancer team is in the process
  // of migrating to stage.
  [
    new RegExp('.*stage.hallofreelancer.com'),
    'https://preview-consent.hallofreelancer.com',
  ],
  [
    new RegExp('manager-preview.hallofreelancer.com'),
    'https://preview-consent.hallofreelancer.com',
  ],

  // Xingboxes
  [
    new RegExp('.*kenv.xing.com'),
    (host) => `${window.location.protocol}//${host}`,
  ],

  // Localhost
  [new RegExp('.*localhost'), () => `http://localhost:3000`],
  [new RegExp('.*192.168'), () => `http://localhost:3000`],

  // Production hosts. NOTE TO NOT OVERRIDE THEM with preview ones
  [new RegExp('.*xing.com'), 'https://consent.xing.com'],
  [new RegExp('.*xing-events.com'), 'https://consent.xing-events.com'],
  [new RegExp('.*xing-news.com'), 'https://consent.xing-news.com'],
  [new RegExp('.*new-work.se'), 'https://consent.new-work.se'],
  [new RegExp('.*anschreiben.com'), 'https://consent.anschreiben.com'],
  [new RegExp('.*bewerbung.com'), 'https://consent.bewerbung.com'],
  [new RegExp('.*lebenslauf.com'), 'https://consent.lebenslauf.com'],
  [new RegExp('.*kununu.com'), 'https://consent.kununu.com'],
  [new RegExp('.*hallofreelancer.com'), 'https://consent.hallofreelancer.com'],
];

const matchHost = (host) => {
  const lookup = HostLookupTable.find((tuple) => {
    return host.match(tuple[0]);
  });

  if (lookup && typeof lookup[1] === 'function') {
    return lookup[1](host);
  }

  if (lookup) return lookup[1];

  return null;
};

export const hostResolver = () => {
  const matchedHost = matchHost(window.location.hostname);
  if (!matchedHost || matchedHost.length === 0) {
    window.console.warn('[HostResolver] Could not determine host');
    return null;
  }

  return matchedHost;
};
