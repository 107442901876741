export const PAGEVIEW = 'pageview';

const DEFAULT = 'default';

const matchConfigByEnv = (env, map) => {
  return Object.assign({}, map[env] || map[DEFAULT]);
};

const matchEnvByHost = (map) => {
  const hostname = (global.location && global.location.hostname) || 'default';

  return map[hostname] || map[DEFAULT] || 'preview';
};

export { matchConfigByEnv, matchEnvByHost };
