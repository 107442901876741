function mergeQueryParams(params) {
  const entries = params.map(([key, value]) => {
    return [key, value]
      .filter(e => e !== undefined)
      .map(e => encodeURIComponent(e))
      .join('=');
  });

  return `?${entries.join('&')}`;
}

export default mergeQueryParams;
