const crawlerRegex =
  /adidxbotc|Applebot\/|archive.org_bot|asterias\/|Baiduspider\/|bingbot\/|BingPreview\/|DuckDuckBot\/|FAST-WebCrawler\/|Feedspot|Feedspotbot\/|Google Page Speed Insights|Google PP|Google Search Console|Google Web Preview|Googlebot\/|Googlebot-Image\/|Googlebot-Mobile\/|Googlebot-News|Googlebot-Video\/|Google-SearchByImage|Google-Structured-Data-Testing-Tool|Chrome-Lighthouse|heritrix\/|iaskspider\/|Mediapartners-Google|msnbot\/|msnbot-media\/|msnbot-NewsBlogs\/|msnbot-UDiscovery\/|SEMrushBot|special_archiver\/|Siteimprove|Y!J-ASR\/|Y!J-BRI\/|Y!J-BRJ\/YATS|Y!J-BRO\/YFSJ|Y!J-BRW\/|Y!J-BSC\/|Yahoo! Site Explorer Feed Validator|Yahoo! Slurp|YahooCacheSystem|Yahoo-MMCrawler\/|YahooSeeker\//;

const isCrawler = (userAgent) => {
  return crawlerRegex.test(userAgent);
};

const isInvalidClient = (navigator) => {
  const ua = navigator.userAgent;

  return (
    !navigator.cookieEnabled ||
    isCrawler(ua) ||
    /ttt_webview/.test(ua) ||
    /xing/.test((ua || '').toLowerCase())
  );
};

export const supportedRunningPlatform = (navigator) => {
  return !isInvalidClient(navigator);
};
