import { Mixin } from '@xingternal/hops-mixin';

export const createApolloLinkMixin = (HttpLink, getCustomGraphqlUri) => {
  return class extends Mixin {
    constructor(config, element, { graphql: options = {} } = {}) {
      super(config, element);

      this.options = options;
    }

    getApolloLink() {
      const customGraphqlUri =
        typeof getCustomGraphqlUri === 'function' ? getCustomGraphqlUri() : '';

      return (
        this.options.link ||
        new HttpLink({
          uri: customGraphqlUri || this.config.graphqlUri,
          fetch: this.fetch,
        })
      );
    }
  };
};
