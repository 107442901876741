import React, { createContext, useState } from 'react';

const initialState = { disableBanner: true };
const CookieConsentContext = createContext([{}, () => {}]);
const CookieConsentProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(initialState);

  return (
    <CookieConsentContext.Provider value={[cookieConsent, setCookieConsent]}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export { CookieConsentProvider };
export default CookieConsentContext;
