import queryString from 'query-string';

const PARAMS_WHITELIST = ['register_redirect_to', 'signup_channel'];

export default (externalUrl, location) => {
  const search = queryString.parse(location.search);

  const queryParams = PARAMS_WHITELIST.reduce((acc, name) => {
    if (search[name]) acc[name] = search[name];
    return acc;
  }, {});

  const signupUrl = new URL(externalUrl);

  signupUrl.pathname = 'signup';
  signupUrl.search = queryString.stringify({
    registration: '1',
    ...queryParams,
  });

  return signupUrl.href;
};
