// @ts-check
// @ts-ignore
import { DataScienceTrackingFullFormat } from './mutations';

/**
 * @typedef {import('@xingternal/ds-tracking').OtherTrackingInput} OtherTrackingInput
 */

/**
 * @param messages {OtherTrackingInput[]}
 */
function createDataScienceTrackingMutation(messages) {
  return JSON.stringify({
    query: DataScienceTrackingFullFormat.loc.source.body,
    variables: {
      input: {
        source: 'MESSAGES',
        messages,
      },
    },
  });
}

/**
 * @param fetch {typeof fetch}
 * @param messages {OtherTrackingInput[]}
 */
export default async function sendDataScienceTrackingMutation(fetch, messages) {
  if (!messages.length) {
    return;
  }

  await fetch('/xing-one/api', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: createDataScienceTrackingMutation(messages),
  });
}
