const noop = () => {};
const promise = () => Promise.resolve();

const createMock = () => {
  return {
    version: `0.0.0-mock`,
    baseData: {},
    init: promise,
    pageview: promise,
    event: promise,
    nwtEvent: promise,
    withPerformanceMetrics: promise,
    publish: promise,
    subscribe: noop,
    assign: noop,
    _dl: {},
    _rawPageview: promise,
    _rawEvent: promise,
    _unload: noop,
  };
};

export default createMock;
