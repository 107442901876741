import { Promise } from './polyfills';

class Future {
  constructor() {
    this.reset();
  }

  reset() {
    this.resolved = false;
    this._promise = new Promise(resolve => {
      this.resolve = value => {
        resolve(value);
        this.resolved = true;
      };
    });
  }

  resetAndResolve(value) {
    this.resolved && this.reset();
    this.resolve(value);
  }

  then(value) {
    return this._promise.then(value);
  }
}

export default Future;
