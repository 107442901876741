import 'cross-fetch/polyfill';

const ALTERING_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];
const COOKIE_REGEXP = /(?:^|;\s*)xing_csrf_token=([^;]+)/;
const HTTP_HEADER_KEY = 'X-CSRF-Token';

const methodRequiresCsrfToken = (method = '') => {
  return ALTERING_METHODS.includes(method.toUpperCase());
};

const getCsrfCookie = () => {
  const match = global.document && global.document.cookie.match(COOKIE_REGEXP);
  return match ? match[1] : '';
};

/**
 * Fetch header option can either be a literal or a global.Headers object
 * This function normalizes it to always be of global.Headers
 */
const getHeaderObject = (headers = {}) => {
  if (headers instanceof global.Headers) {
    return headers;
  }

  return new global.Headers(headers);
};

export default function addCsrfHeader(options) {
  const csrfCookie = getCsrfCookie();

  if (!methodRequiresCsrfToken(options.method) || !csrfCookie) {
    return options;
  }

  options = { ...options };
  options.headers = getHeaderObject(options.headers);

  if (!options.headers.has(HTTP_HEADER_KEY)) {
    options.headers.set(HTTP_HEADER_KEY, csrfCookie);
  }

  return options;
}
