import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from '@xingternal/checkbox';
import { Label } from '@xingternal/label';
import cx from 'classnames';

import { UserChoicesTypes, CategoryTypes } from '../types';
import { updateChoice } from '../data-sources/tracking-service';
import sharedStyles from '../shared.css';

import checkboxStyles from './checkbox.css';

const getInputProps = (category, userChoices) => {
  if (category.mandatory) {
    return { disabled: true, on: true };
  }

  return { disabled: false, on: userChoices[category.name] };
};

const handleChoice = (category, userChoices, setUserChoices) => (evt) => {
  const mergedChoices = updateChoice(
    userChoices,
    category.name,
    evt.target.checked
  );

  setUserChoices(mergedChoices);
};

const CategoryPanel = ({ category, userChoices, setUserChoices }) => {
  const checkboxId = `checkbox-${category.name}`;
  return (
    <div className={checkboxStyles.categoryContainer}>
      <Checkbox
        {...getInputProps(category, userChoices)}
        onChange={handleChoice(category, userChoices, setUserChoices)}
        id={checkboxId}
      />
      <div className={checkboxStyles.textSection}>
        <Label
          className={cx(
            checkboxStyles.categoryTitle,
            'consent-banner-overlay-category-title'
          )}
          htmlFor={checkboxId}
        >
          {category.title}
        </Label>
        <div
          className={cx(
            'consent-banner-overlay-category-description',
            checkboxStyles.categoryDescription,
            sharedStyles.text,
            sharedStyles.smallText
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: category.description }}
        />
      </div>
    </div>
  );
};

CategoryPanel.propTypes = {
  userChoices: UserChoicesTypes,
  setUserChoices: PropTypes.func.isRequired,
  category: CategoryTypes,
};

export default CategoryPanel;
