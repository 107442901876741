import React from 'react';

import PropTypes from 'prop-types';
import { BodyCopy, Headline } from '@xingternal/typography';
import { Button, TextButton } from '@xingternal/button';
import cx from 'classnames';

import { Accordion } from './Accordion';
import { UserChoicesTypes, ModalConfigurationTypes } from './types';
import styles from './CookieConsentBannerContent.css';
import { useCookieConsentContext } from './CookieConsentProvider';

const acceptInternalAll =
  (acceptAll, tttService, hideOmView = () => {}) =>
  () => {
    tttService.trackAcceptAll();
    hideOmView();
    acceptAll();
  };

const CookieConsentBannerContent = ({ resources, acceptAll, tttService }) => {
  const { setShowSettings } = useCookieConsentContext();

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <div className={styles.bannerText}>
          <Headline
            as="h2"
            id="consent-banner-title"
            className={styles.bannerTitle}
            size="xxlarge"
          >
            {resources.banner.title}
          </Headline>
          <BodyCopy
            size="medium"
            className="consent-banner-description"
            id="consent-banner-text-top"
            dangerouslySetInnerHTML={{ __html: resources.banner.bodyTop }}
          />
          {resources.banner.collapsibleInformation.map((info) => (
            <Accordion title={info.title} body={info.body} key={info.title} />
          ))}
          <div
            className={cx(styles.bodyBottom, 'consent-banner-description')}
            id="consent-banner-text-bottom"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: resources.banner.bodyBottom }}
          />
        </div>
        <div className={styles.buttonContainer}>
          <TextButton
            onClick={() => {
              tttService.trackShowSettings();
              setShowSettings(true);
            }}
            id="consent-settings-button"
            data-qa="consent-button-settings"
            className={cx(
              'consent-banner-button-settings',
              styles.reviewButton
            )}
            size="small"
            sizeConfined="medium"
          >
            {resources.banner.reviewButton}
          </TextButton>
          <Button
            id="consent-accept-button"
            variant="primary"
            size="small"
            sizeConfined="medium"
            className={cx(
              'consent-banner-button-accept-all',
              styles.acceptButton
            )}
            onClick={acceptInternalAll(acceptAll, tttService)}
          >
            {resources.banner.acceptAllButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

CookieConsentBannerContent.propTypes = {
  userChoices: UserChoicesTypes,
  resources: PropTypes.shape({
    banner: PropTypes.shape({
      bodyTop: PropTypes.string,
      bodyBottom: PropTypes.string,
      title: PropTypes.string,
      reviewButton: PropTypes.string,
      acceptAllButton: PropTypes.string,
      collapsibleInformation: PropTypes.arrayOf(
        PropTypes.shape({
          body: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    }),
    modal: ModalConfigurationTypes,
  }).isRequired,
  acceptAll: PropTypes.func.isRequired,
  acceptSelected: PropTypes.func.isRequired,
  setShowSettings: PropTypes.func.isRequired,
  tttService: PropTypes.object.isRequired,
};

export default CookieConsentBannerContent;
