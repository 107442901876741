import { WARN } from './constants';

let minLevel = WARN;

const getMinimumLogLevel = () => {
  return minLevel;
};

const setMinimumLogLevel = logLevel => {
  minLevel = logLevel;
};

export { getMinimumLogLevel, setMinimumLogLevel };
