import styled from 'styled-components';

import {
  xdlColorBackground,
  spaceXL,
  spaceL,
  spaceM,
  cornerRadiusL,
  scale100,
  xdlColorBorderSoft,
  spaceXXL,
} from '@xingternal/tokens';
import { BodyCopy } from '@xingternal/typography';
import { Button } from '@xingternal/button';
import { ContentBanner, StatusBanner } from '@xingternal/banner';
import { IconFilePDF } from '@xingternal/icons';
import { ProgressBar } from '@xingternal/progress-bar';

export const Container = styled.div`
  background-color: ${xdlColorBackground};
  padding: ${spaceL} ${spaceL};
  margin-bottom: ${spaceXXL};
  border-radius: ${cornerRadiusL};
  border: 2px solid ${xdlColorBorderSoft};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${spaceL};
`;

export const ImageContainer = styled.div`
  margin-right: ${scale100};
  margin-bottom: 0;
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${spaceM};
`;

export const HelperText = styled(BodyCopy)`
  color: #5e737d;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${spaceXL};

  & div {
    justify-content: flex-start;
  }
`;

export const StyledContentBanner = styled(ContentBanner)`
  display: flex;
  align-items: flex-start;
  margin-top: ${spaceXL};
  background: transparent;
`;

export const StyledIconFilePDF = styled(IconFilePDF)`
  height: 48px;
  margin-right: ${spaceL};
`;

export const UploadInput = styled.input`
  position: absolute;
  top: 0;
  left: -1000em;
  height: 0;
  width: 0;
  opacity: 0;
`;

export const ResultsContainer = styled.div`
  width: 100%;
`;

export const StyledProgressBar = styled(ProgressBar)`
  margin-top: ${spaceM};
`;
