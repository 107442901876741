import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import useWindowLoginOptions from './useWindowLoginOptions';
import { validateLogin, createLogin } from './actions';
import { INITIAL_FORM_VALUES } from './constants';

import View from './View';

import messages from './messages';

const getInitialFormValues = ({ current: { elements } }) => {
  const getFieldByName = name => {
    const fieldKey = Object.keys(elements).find(i => elements[i].name === name);
    return elements[fieldKey];
  };

  const usernameField = getFieldByName('username');
  const passwordField = getFieldByName('password');

  return {
    ...INITIAL_FORM_VALUES,
    username: usernameField.value,
    password: passwordField.value,
  };
};

const LoginForm = ({
  externalUrl,
  externalLoginUrl,
  authPartner = null,
  loggedOutSid = null,
  headlineMessage = null,
  noHeadline = false,
  noSignupLink = false,
  loginParams = null,
  initialErrorKey = null,
  onError = () => null,
  isLoginDomain = false,
  showAppleSignIn = true,
  showGoogleSignIn = true,
  usernamePlaceholderMessage = null,
  passwordPlaceholderMessage = null,
  showTroubleLoggingInLink = true,
  onlyfyToken = null,
}) => {
  const [initialValues, setInitialValues] = useState(INITIAL_FORM_VALUES);
  const formRef = useRef(null);

  const validate = values => validateLogin(values);
  const submit = async (values, actions) => {
    const { setSubmitting, setErrors } = actions;

    const loginOptions = useWindowLoginOptions(loginParams);

    const payload = {
      ...loginOptions,
      username: values.username,
      password: values.password,
      perm: values.perm ? '1' : '0',
    };

    try {
      await createLogin(externalLoginUrl, payload);
    } catch (e) {
      let general;

      if (e.jsonResponse && e.jsonResponse.description) {
        general = e.jsonResponse.description;
      } else {
        general = messages.submitRequestError;
      }

      const errors = general && { general };

      setSubmitting(false);
      setErrors(errors);
      onError(errors, e);
    }
  };

  

  useEffect(() => {
    // this re-initializes the form with browser-prefilled values
    // despite javascript execution taking too long to register the
    // change event triggered by the browser-prefill
    setInitialValues(getInitialFormValues(formRef));
  }, []);

  const viewProps = {
    externalUrl,
    externalLoginUrl,
    headlineMessage,
    isLoginDomain,
    loggedOutSid,
    noSignupLink,
    authPartner,
    noHeadline,
    formRef,
    showAppleSignIn,
    showGoogleSignIn,
    usernamePlaceholderMessage,
    passwordPlaceholderMessage,
    showTroubleLoggingInLink,
    onlyfyToken,
  };

  if (typeof initialErrorKey === 'string' && initialErrorKey !== '') {
    const message =
      Object.values(messages).find(({ id }) => id === initialErrorKey) ||
      messages.submitRequestError;
    viewProps.errors = {
      general: message,
    };
    viewProps.submitCount = 1;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formikProps => <View {...formikProps} {...viewProps} />}
    </Formik>
  );
};

LoginForm.propTypes = {
  /** EXTERNAL_URL - found in shared-dynamic-config */
  externalUrl: PropTypes.string.isRequired,
  /** EXTERNAL_LOGIN_URL - found in shared-dynamic-config */
  externalLoginUrl: PropTypes.string.isRequired,
  /** Used as additional CORS validation when not on login.xing.com.
   * Serverside generated logged out sid for additional CORS validation.
   * Its implementation can be found in gems/xing-rails_core_tools. */
  loggedOutSid: PropTypes.string,
  /** optional error callback - returns form errors */
  onError: PropTypes.func,
  /** overridable login options: 'destUrl' and 'setLanguage'. Already forwarded from window.location.search if present */
  loginParams: PropTypes.shape({
    /** destination url the user may be redirected to */
    destUrl: PropTypes.string,
    /** force update of language cookie on login */
    setLanguage: PropTypes.bool,
  }),
  /** overridable react-intl message displayed above form */
  headlineMessage: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
  }),
  /** Provide this prop as true if you render the component on the xing login domain.
   * If set to true, some links in the form will be react-router links instead of normal anchors */
  isLoginDomain: PropTypes.bool,
  /** when set to true, no headline is rendered above the form */
  noHeadline: PropTypes.bool,
  /** when set to true, no signup link is rendered below the form */
  noSignupLink: PropTypes.bool,
  /** Optional authPartner. If given, renders a notice above form that it is used for a partner login */
  authPartner: PropTypes.shape({
    name: PropTypes.string,
    website: PropTypes.string,
  }),
  /** pre-fills the 'general' error message with error with given key */
  initialErrorKey: PropTypes.string,
  /** whether or not to show the Google sign-in button (default to true) */
  showGoogleSignIn: PropTypes.bool,
  /** whether or not to show the Apple sign-in button (default to false) */
  showAppleSignIn: PropTypes.bool,
  /** custom username placeholder (uses default one if null/empty) */
  usernamePlaceholderMessage: PropTypes.string,
  /** custom password placeholder (uses default one if null/empty) */
  passwordPlaceholderMessage: PropTypes.string,
  /** whether or not to show the forgot password / trouble logging in link (default to true) */
  showTroubleLoggingInLink: PropTypes.bool,
  /** Token for Onlyfy logins, passed from login-frontend */
  onlyfyToken: PropTypes.string,
};

export default LoginForm;
