// @ts-check

import * as Sentry from '@sentry/browser';

/**
 * @type {import('@xingternal/hops-sentry').captureException}
 */
function captureException(error, extras) {
  Sentry.withScope((scope) => {
    if (extras) {
      scope.setExtras(extras);
    }
    Sentry.captureException(error);
  });
}

export { Sentry, captureException };
