import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '@xingternal/button';
import { IconArrowDown, IconArrowUp } from '@xingternal/icons';
import { BodyCopy } from '@xingternal/typography';
import cx from 'classnames';

import styles from './Accordion.css';

export const Accordion = ({ title, body }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <React.Fragment>
      <TextButton
        className={cx(styles.title, 'accordion-title')}
        onClick={() => setShowMore(!showMore)}
        icon={showMore ? IconArrowUp : IconArrowDown}
        iconAfter
        size="medium"
      >
        {title}
      </TextButton>
      <BodyCopy
        className={cx(
          styles.body,
          { [styles.visible]: showMore },
          'accordion-body'
        )}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </React.Fragment>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
